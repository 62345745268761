import React, { useEffect, useState } from 'react';
import './Paymentsucess.css';
import { MDBBtn } from 'mdb-react-ui-kit'
import { Link,  useParams } from 'react-router-dom';
import AuthService from '../../servicecall/auth.service';



function Paymentsucess() {
  const [transactionDetails, setTransactionDetails] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'))
  const params = useParams();

  const transactiondetail = async () => {
    const details = await AuthService.TransactionDetails(params.id)
    setTransactionDetails(details)
    localStorage.removeItem("exampleCartData")
    localStorage.setItem('freeitem', [])
  }
  useEffect(() => {
    transactiondetail();
  }, )


  return (

    <div>
      {/* {console.log("ransactionDetails : "+JSON.stringify(transactionDetails))}
       {console.log("transactionDetails.length : "+transactionDetails.length)}
       {console.log("transactionDetails.state : "+transactionDetails[0].state)} */}


      {(transactionDetails.length > 0) ? <>
       
          <div>
            <div className='image-container'>
            {(transactionDetails[0].state === "COMPLETED") ?  <img src='../Images/sucess.png' alt='payment-sucesss' /> : <img src='../Images/failed-icon-8.jpg' alt='payment-fail' /> }
              
            </div>
            <h1>{(transactionDetails[0].state === "COMPLETED") ?  "Order Placed Sucessfully" : " Order "+transactionDetails[0].state }</h1>
            <div className='transaction-details'>
              <h4>Transaction details</h4>
              {transactionDetails.map((detail) => {
                return (
                  <>
                    <p>
                      <span>Status</span>
                      <span>{detail.state}</span>
                    </p>
                    <p>
                      <span>Payment Type</span>
                      <span>{detail.type}</span>
                    </p>
                    <p>
                      <span>Mobile</span>
                      <span>{userDetails.phoneNumber}</span>
                    </p>
                    <p>
                      <span>E-mail</span>
                      <span>{userDetails.email}</span>
                    </p>
                    {(detail.transactionId) ? <>
                    <p>
                      <span>Amount Paid (in Rupees)</span>
                      <span>{detail.amount}</span>
                    </p>
                    <p>
                      <span>Transaction Id</span>
                      <span>{detail.transactionId}</span>
                    </p>
                    </> : <> </>}
                  </>
                )
              })}
            </div>
          </div> 
          </> : <>
        <div className='image-container'>
          <img src='../Images/failed-icon-8.jpg' alt='payment-fail' />
        </div>
        <h1>Opppsss!!!!!
          Order Not Placed Due to Transaction Failure ....</h1>
      </>}
<div className='image-container'>
      <Link to={'/shop'} >
        <MDBBtn size='lg' style={{ background: "red", margin: "25px 0", padding: "12px 50px" }}>
          Continue Shopping
        </MDBBtn>
      </Link>
      </div>
    </div>
  )
}

export default Paymentsucess
