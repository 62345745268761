import React from 'react';
import './Slogan.css';
import { sloganData} from '../../components/JsonData/Data';
import Carousel from 'react-bootstrap/Carousel';

function Slogan() {
  return (
   
   <>
    <Carousel className='slogen' autoPlay={true}
    interval={50000}
    controls={false}
    indicators={false}> 
    {sloganData.sloganData.map((sloganData) => (
   
         <Carousel.Item >
         <div className='slogen' >
            <div className='col-8 container'>
              <p className='slogan'>{sloganData.slogan}</p>
             
             <div className='slg-hr1 '>
                 <hr className='slg-hr'/>
                </div>
            </div>
          </div>
          
        </Carousel.Item>
      ))}
  </Carousel>
   
        {/* console.log(sloganData);
        return (
          <div className='' >
            <div className=''>
              <p className=''>{sloganData.slogan}</p>
              <p>{sloganData.name}</p>
            </div>
          </div>
        )
      })} */}
   </>
  )
}

export default Slogan