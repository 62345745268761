import React from 'react'
import { Sliderlow } from '../../components/JsonData/Data';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import {MDBCardImage,  MDBRipple } from "mdb-react-ui-kit";
import "./newarival.css";

const NewArrival = () => {
    
const filterdata2 = Sliderlow.Sliderlow.filter((Sliderlow) => Sliderlow.id === 2);
  return (
    <div className='row'>
      <div className="col-12 col-sm-6 container" style={{ margin: "auto" }}>
          {filterdata2.map((Sliderlow) => (
            <div className='pro-text' key={Sliderlow.name}>
              <h1 className='col-12 col-sm-8 new-arival'>{Sliderlow.name}</h1>
              <p className='col-12 col-sm-8 new-arival description'  >{Sliderlow.description}</p>
            </div>
          ))}
        </div>



        <div className="col-12 col-sm-6" >
          {filterdata2.map((Sliderlow) => (
            <div key={"NewArrival"+Sliderlow.name}>
              <div className="frame col-xs-6">
                <MDBRipple  rippleTag="a" 
                    rippleColor="light"
                className="bg-image rounded hover-zoom">
                    <MDBCardImage
                      src={Sliderlow.image}
                      fluid
                      className="pro-img"
                    />          
                    <div className="hover-overlay">
                      <div
                        className="mask"></div>
                    </div>
                  </MDBRipple>

              </div>

            </div>
          ))}
        </div>
    </div>
  )
}

export default NewArrival