import React, {useState} from 'react'
import { MDBContainer, MDBRow, MDBCol,MDBCard,MDBCardBody,MDBInput,MDBCheckbox, MDBBtn } from 'mdb-react-ui-kit'
 
import {Form} from 'react-bootstrap';
import {   useParams } from 'react-router-dom';
import AuthService from '../servicecall/auth.service';
import { Link } from 'react-router-dom/dist';

const Registration = () => {
  const { id } = useParams();
const [username, setUsername] = useState("");
const [password, setPassword] = useState("");
const [fullName, setFullName] = useState("");
const [email, setEmail] = useState("");
const [phoneNumber, setPhoneNumber] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [referalBy, setReferalBy] = useState(id ? id : "");
const[message,setMessage]=useState();
const[error,setError]=useState(false);


  const handleSubmit = async(e) => {
    e.preventDefault();

    if(phoneNumber.length < 10){
      alert("invalid Phonenumber")
      return
    }

    if(password !== confirmPassword){
      alert("Password and Confirm Password doesn't match");
      return
    }

    let msg = await AuthService.registration(fullName,
        email,
        phoneNumber,
        username,
        password,
        referalBy)

        if(msg?.statuscode === 400 ){
          setMessage(msg.message)
          setError(true)
        }
    
}
  return (
   <>
   <Form onSubmit={handleSubmit}> 
     <MDBContainer fluid>
              <MDBRow className='d-flex justify-content-center align-items-center clearfix font1'>
                <MDBCol col='12'>
                  <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                    <MDBCardBody className='p-5 w-100 d-flex flex-column'>
                      <h2 className="fw-bold mb-2 text-center" style={{ color: "red" }}>Register</h2>
                      <p className="text-white-50 mb-3">Please enter your login and password!</p>
                      {error ? <div style={{width: "40%",textAlign:'center',backgroundColor: "#ed5249",color: "#fff",padding: "5px",margin: "0 auto"}}>{message}</div> : null}
                      <MDBInput wrapperClass='mb-4 w-100' label='Name' id='formControlLg' type='text' size="lg" onChange={(e) => {setFullName(e.target.value); e.target.value.replace(/[0-9]/g,'')} } value={fullName} onKeyDown={e => {if(!(/[a-zA-Z ]/g).test(e.key)) e.preventDefault();}} required />
                      <MDBInput wrapperClass='mb-4 w-100' label='Email address' id='formControlLg' type='email' size="lg" onChange={(e) => setEmail(e.target.value)} value={email} required />
                      <MDBInput wrapperClass='mb-4 w-100' label='Phone No' id='formControlLg' type='text' size="lg" onChange={(e) => {setPhoneNumber(e.target.value)}} value={phoneNumber} maxLength={10}  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} />
                      <MDBInput wrapperClass='mb-4 w-100' label='User Name' id='formControlLg' type='text' size="lg" onChange={(e) => setUsername(e.target.value)} value={username} required />
                      <MDBInput wrapperClass='mb-4 w-100' label='Password' placeholder='Minimum length 6 characters' id='formControlLg' type='password' minLength={6} size="lg" onChange={(e) => setPassword(e.target.value)} value={password} required />
                      <MDBInput wrapperClass='mb-4 w-100' label='Confirm Password' placeholder='Minimum length 6 characters' id='formControlLg' type='password' minLength={6} size="lg" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                      <MDBInput wrapperClass='mb-4 w-100' label='Referal Code (optional)' id='formControlLg' type='text' size="lg" onChange={(e) => setReferalBy(e.target.value)} value={referalBy} />
                      <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4' label='Remember password' />
                      <MDBBtn type='submit' size='lg' style={{ background: "red", fontWeight: "bold" }}>
                        Register
                      </MDBBtn>
                      <p className="mb-5 pb-lg-2" style={{ color: '#393f81', margin: "15px" }}><Link to={'/login'} >Already Registered Click Here</Link></p>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
  </Form>
   </>
  )
}

export default Registration