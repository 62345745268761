 
import { Sliderlow } from '../../components/JsonData/Data';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { MDBCardImage, MDBRipple } from "mdb-react-ui-kit";
import "./aboutsection.css";
import React, { useState } from 'react';

const AboutSection = () => {
  const filterdata1 = Sliderlow.Sliderlow.filter((Sliderlow) => Sliderlow.id === 1);
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle the text expansion
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className='row' style={{ margin: "1%" }}>
      <div className="col-sm-5 maindiv">
        {filterdata1.map((Sliderlow, index) => (
          <div key={"AboutSection" + index}>
            <div className="frame col-xs-6">
              <MDBRipple rippleTag="a"
                rippleColor="light"
                className="bg-image rounded hover-zoom">
                <MDBCardImage
                  src={Sliderlow.image}
                  fluid
                  className="pro-img"
                />

                <div className="hover-overlay">
                  <div
                    className="mask"></div>
                </div>
              </MDBRipple>
            </div>
          </div>
        ))}
      </div>


      <div className="col-12 col-sm-6 container" style={{ margin: "auto" }}>
      {filterdata1.map((Sliderlow, index) => (
        <div className='text-left' key={"AboutSlider" + index}>
          <p className='text-left'>
            We the Team STK... are a bunch of committed entrepreneurs.
            Focusing on "co-creation" at all levels, we aim to bridge the gap between nature and consumers.
          </p>
          <p className={`text-left ${isExpanded ? 'expanded' : 'collapsed'}`}>
            Our team is committed to delivering the wonderful gifts that nature has in store, in form of healthy, nutritious and organic foods.
            The aim is to create an offering by combining the natural foods with modern techniques so as to enable the human kind to rejoice these gifts.
            POPMAK is one such creation. It is a healthy munching solution for all ages. In fact mothers have started giving it space in their kids lunch boxes.
            We are happy & sure to bring in such product line up which can add values in your life.
            We are just four years old company, aiming big to deliver and in this endeavor we will remain child like enthusiast for ever “Expect more surprises from us soon!!!!
          </p>
          <button onClick={toggleReadMore} className="read-more-btn">
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        </div>
      ))}
    </div>
  
    </div>
    
  

    
  )
}

export default AboutSection