import React from 'react';
import '../OrderSummary/OrderSummary.css';
import Table from 'react-bootstrap/Table';
import UserLefNavbar from './userLefNavbar';
import Data from "../../components/Data/ProductData";

import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';


const OrderSummary = () => {

    const [OrderData, setOrderData] = useState();

    const userId = JSON.parse(localStorage.getItem('userId'))

    const onClickHandler = (e) => {
        const hiddenElement = e.currentTarget.nextSibling;
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    };
    const baseURL = process.env.REACT_APP_API_URL;
    const token = `Bearer ` + localStorage.getItem('token');
    const config = {
        headers: {
            'Authorization': token,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        }
    };


    const orderBuyer = async () => {
        await axios
        .post(baseURL + "orders/byuser", { userId: userId }, config
        )
        .then(data => {
                if (data.data.statuscode === "200") {
                    setOrderData(data.data.message);
                }
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        orderBuyer();
    },)

    return (
        <div className='row'>
            <UserLefNavbar />
            <div className='col-12 col-sm-9'>
                <div className='order-heading'>
                    <h1>Order Details</h1>
                </div>
                <Table className='order-table table-bordered' >
                    <tr className='table-head'>
                        <th>Sr No</th>
                        <th>Order No</th>
                        <th>Order Date</th>
                        <th >Customer Name</th>
                        <th>Total Amount</th>
                    </tr>
                    {OrderData?.map((item, idx) => {
                        return (
                            <tbody key={item.id} >
                                <tr id={item.id} onClick={onClickHandler}   >
                                    <td >{idx + 1}</td>
                                    <td>{item.orderNumber}</td>
                                    <td>{item.orderDate}</td>
                                    <td>{item.fullName}</td>
                                    <td>₹ {item.grandTotal} <i class={item.id} aria-hidden="true"></i>
                                    </td>
                                </tr>
                                <tr className="collapse"    >
                                    <td colSpan={5}>
                                    <table style={{width:"100%"}}>
                                        <tr>
                                            <th style={{width:"30%"}}>
                                                Product Name
                                            </th>
                                            <th style={{width:"30%"}}>

                                            </th>
                                            <th style={{width:"30%"}}>
                                                Product Price
                                            </th>
                                            <th style={{width:"30%"}}>
                                                Quantity
                                            </th>
                                        </tr>
                                    </table>
                                    {
                                        item.orderProduct.map((product)=> {
                                            const filteredProduct = Data.filter((data) => data.id === product.prId)
                                            return (filteredProduct.map((item) => {
                                                return(
                                                    <>
                                                        <table style={{width:"100%"}}>
                                                            <tr>
                                                                <td style={{width:"30%"}}>
                                                                    {product.prName}
                                                                </td>
                                                                <td style={{width:"30%"}}>
                                                                    <img className='order-product-img' src={item.image} alt={item.name} style={{width:'30px'}} />
                                                                </td>
                                                                <td style={{width:"30%"}}>
                                                                    ₹ {product.prPrice}
                                                                </td>
                                                                <td>
                                                                    {product.prQuantity}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                )
                                            }))
                                        })
                                    }
                                    </td>


                                </tr>
                            </tbody>
                        );
                    })}
                </Table>




            </div>

        </div>
    )
}

export default OrderSummary