import React, { useEffect, useState, useRef } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBListGroupItem } from 'mdb-react-ui-kit'
import axios from 'axios';


const CartSummary = ({ summaryDetails, cartItems, freeItemCart, address, shippingAddressDetails }) => {

  const discountpercentage = summaryDetails.discountpercentage;
  // const [orderId, setOrderId] = useState();
  const [walletBalance, setWalletBalance] = useState(0.00)
  const [payableAmounts, setpayableAmounts] = useState((summaryDetails.payableAmount).toFixed(2))
  const [walletUsedBalanceAmount, setWalletUsedBalance] = useState(0.00)
  const checkboxRef = useRef();
  // console.log("localStorage.getItem('token')", localStorage.getItem('token'))
  const token = `Bearer ` + localStorage.getItem('token');
  const userIdData = localStorage.getItem('userId');
  const loginUserId = userIdData ? JSON.parse(userIdData) : null;
  const baseURL = process.env.REACT_APP_API_URL;  
  const merchantId = Date.now()
  const config = {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
  };

  // const initiatePaymentPhonePe = async (merchantId) => {
  //   const bodyParameters = {
  //     amount: payableAmounts,
  //     merchantId: merchantId
  //   }

  //   // const urlCall = baseURL + "phonepe/sandbox/initializepayment";     // for Sandbox and Testing
  //   const urlCall = baseURL + "phonepe/initializepayment/";    //For Production Use
  //   axios.post(urlCall, bodyParameters, config).then(
  //     (responce) => {
  //       window.location.href = responce.data.messege
  //     }
  //   )
  // }

  // useEffect(() => {
  //   if (summaryDetails.subtotalPrice === 0.00) {
  //     navigate('../cart');
  //   }
  // }, [summaryDetails.subtotalPrice])

  const cartDetailsInDatabase = async (merchantId) => {
    let todayDate = new Date();
    todayDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
    const productData = cartItems?.map((items) => {
      let itemPriceAfterDiscount = items.basePrice - (items.basePrice * discountpercentage / 100)
      const prSubtotal = (parseFloat(itemPriceAfterDiscount) * parseFloat(items.quantity))
      const discountedAmount = parseFloat(items.basePrice * items.quantity * discountpercentage / 100)
      const prGST = (parseFloat(prSubtotal) * (parseFloat(items.taxpercent) / 100))
      const totalAmount = (parseFloat(prSubtotal) + parseFloat(prGST));
      return {
        "prId": items.id,
        "prName": items.name,
        "prSpecifications": items.description,
        "prPrice": itemPriceAfterDiscount,
        "prQuantity": items.quantity,
        "gstPercentage": items.taxpercent,
        "prSubTotal": prSubtotal.toFixed(2),
        "prDiscountDetails": discountpercentage,
        "prDiscountAmount": discountedAmount,
        "prAccessibleAmount": prSubtotal.toFixed(2),
        "prGstAmount": prGST,
        "prGrandTotal": totalAmount,
        "orderStatus": 1,
        "createdBy": loginUserId,
        "updatedBy": loginUserId
      }
    })


    const freeProductData = freeItemCart?.map((items) => {
      return {
        "prId": items.id,
        "prName": items.name,
        "prSpecifications": items.description,
        "prPrice": 0,
        "prQuantity": items.quantity,
        "gstPercentage": 0,
        "prSubTotal": 0,
        "prDiscountDetails": "",
        "prDiscountAmount": 0,
        "prAccessibleAmount": "",
        "prGstAmount": 0,
        "prGrandTotal": 0,
        "orderStatus": 1,
        "createdBy": loginUserId,
        "updatedBy": loginUserId
      }
    })

    const orderDetails = {
      "orderNumber": merchantId,
      "orderDate": todayDate,
      "invoiceNumber": "",
      "userId": loginUserId,
      "fullName": JSON.parse(localStorage.getItem('fullName')),
      "subTotal": summaryDetails.subtotalPrice,
      "promoCode": summaryDetails.promoCode,
      "discountAmount": summaryDetails.discountAmount,
      "shippingAmount": summaryDetails.freightCharges,
      "accessibleAmount": walletUsedBalanceAmount,
      "gstAmount": summaryDetails.gstAmount,
      "grandTotal": summaryDetails.payableAmount,
      "activeStatus": "0",
      "paidStatus": "0",
      "paidBy": "0",
      "transectionDate": todayDate,
      "createdBy": loginUserId,
      "updatedBy": loginUserId,
      "orderFrom": "2",
      "isPaid": "0",
      "isConfirmed": "0",
      "isCompleted": "0",
      "isCancelled": "0",
      "cancelReasonId": "1",
      "customerEmail": address.email,
      "customerPhone": address.phoneNumber,
      "billingAddress": `${address.addressLine1}, ${address.city}, ${address.state} - ${address.pincode}, `,
      "shippingAddress": `${shippingAddressDetails.shippingName}, ${shippingAddressDetails.shippingAddress}, ${shippingAddressDetails.shippingCity} - ${shippingAddressDetails.shippingPincode}, `,
      "numberOfPackages": summaryDetails.totalItems,
      "numberofItems": productData.length,
      "note": `${shippingAddressDetails.shippingInstructions}`,
      "orderItems": [...productData, ...freeProductData],     
      "addressDetailsBilling": {
        "addressType" : 2,     
        "userId" : loginUserId,   
        "addressLine1": address.addressLine1,
        "city": address.city,
        "state": address.state,
        "stateCode": address.stateCode,
        "pincode": address.pincode,
        "gstNo": address.gstNo,
      },
      "addressDetailsShipping": {
        "addressType" : 2,   
        "userId" : loginUserId,     
        "addressLine1": shippingAddressDetails.shippingAddress,
        "city": shippingAddressDetails.shippingCity,
        "state": address.state,
        "stateCode": address.stateCode,
        "pincode": shippingAddressDetails.shippingPincode,
        "gstNo": address.gstNo,
      },
    }

    await axios.post(baseURL + "orders/register/", orderDetails, config)
      .then(async (res) => {
        // await setOrderId(res.data.orderId)
        if(res.data.statuscode === "200"){
          window.location.href = res.data.redirectUrl
          // await initiatePaymentPhonePe(merchantId)
        } else {
          // window.location.href = res.data.redirectUrl
          window.location.href = '../cart/checkout'
        }        
      }).catch(e => {
        window.location.href = '../cart/checkout'
        console.log(e)
      })
  }

  const handlePayment = async () => {
    if((address.addressLine1 === "") || (address.city === "") || (address.state === "") || (address.pincode === "")){
      alert("Please Enter Address Details .....")
    } else {
    await cartDetailsInDatabase(merchantId)
    // await initiatePaymentPhonePe(merchantId)
    }

  }

  let walletUsedbalance = 0;
  const updateTotalAmount = async () => {
   await setpayableAmounts((prevPayableAmount) => {
      const updatedPayableAmount = checkboxRef.current.checked
        ?  (walletUsedbalance = walletBalance, (parseFloat(summaryDetails.payableAmount) - parseFloat(walletBalance)).toFixed(2))
        : parseFloat(summaryDetails.payableAmount).toFixed(2);
      return updatedPayableAmount;
    });

    await setWalletUsedBalance(walletUsedbalance)
    console.log("walletUsedBalance", walletUsedbalance, "walletUsedBalanceAmount", walletUsedBalanceAmount)
  }



  useEffect(() => {
    const getWalletBalance = async () => {
      const userIdData = localStorage.getItem('userId');
      const userId = userIdData ? JSON.parse(userIdData) : null;
      await axios.post(baseURL + "wallets/walletbalance/", { userId: userId }, config)
        .then(async (res) => {
          if (res.data.message[0].total_amount !== null) {
            // console.log(res.data.message[0].total_amount)
            setWalletBalance(res.data.message[0].total_amount)
          }
        }).catch(e => console.log(e))
    };
  
    getWalletBalance(); // Call getWalletBalance directly inside useEffect
  
  }, ); // Include getWalletBalance in the dependency array
  

  return (
    <>
      <MDBCard md={12}>
        <MDBCardBody>
          <p>
            <strong >Cart Summary</strong>
          </p>
          <MDBListGroupItem
            className="d-flex justify-content-between align-items-center border-0 px-0 pb-0" >
            Total Item
            <span> {summaryDetails.totalItems}</span>
          </MDBListGroupItem>
          <MDBListGroupItem
            className="d-flex justify-content-between align-items-center border-0 px-0 pb-0" >
            Sub Total Amount
            <span>₹{(summaryDetails.subtotalPrice).toFixed(2)}</span>
          </MDBListGroupItem>


          <MDBListGroupItem
            className="d-flex justify-content-between align-items-center border-0 px-0 pb-0" >
            Discount Amount
            <span>₹{(summaryDetails.discountAmount)?.toFixed(2)}</span>
          </MDBListGroupItem>
          <MDBListGroupItem
            className="d-flex justify-content-between align-items-center border-0 px-0 pb-0" >
            Shipping Charges
            <span>₹{(summaryDetails.freightCharges)?.toFixed(2)}</span>
          </MDBListGroupItem>

          <MDBListGroupItem
            className="d-flex justify-content-between align-items-center border-0 px-0 pb-0" >
            GST Amount
            <span>₹{(summaryDetails.gstAmount)?.toFixed(2)}</span>
          </MDBListGroupItem>

          <MDBListGroupItem
            className="d-flex justify-content-between align-items-center border-0 px-0 pb-0" >
            <input type='checkbox' ref={checkboxRef} onChange={updateTotalAmount} />POPMAK Wallet Balance
            {/* <span>₹{ walletBalance?.toFixed(2)}</span> */}
            <span>₹ {isNaN(walletBalance) ? "0.00" : parseFloat(walletBalance).toFixed(2)}</span>
          </MDBListGroupItem>

          <MDBListGroupItem
            className="d-flex justify-content-between align-items-center border-0 px-0 pb-0" >
            Payable Amount
            <span>₹{payableAmounts}</span>
          </MDBListGroupItem>
          <div className="d-flex justify-content-center">
            <MDBBtn size='lg' style={{ background: "red", marginTop: "25px", padding: "12px 50px" }}
              // onClick={initiatePaymentPhonePe}
              onClick={handlePayment}
              disabled={!payableAmounts}
            >
              Pay Now
            </MDBBtn>
          </div>
        </MDBCardBody>
      </MDBCard>
    </>
  )
}

export default CartSummary