import React from 'react';
import "./about.css";
// import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Aboutimage, aboutData, Slider } from '../../components/JsonData/Data';
import Slogan from '../../components/slogan/Slogan';

function About() {
  const filtereddata = Slider.Slider.filter((Slider) => Slider.id === 3);


  return (
    <div style={{ zIndex: "0" }}>


       

        {filtereddata.map((Slider, index) => (
          <div className='headerleft' key={"sliderabout" + index}>
            <img
              className="d-block w-100"
              src={Slider.image1}
              alt="First slide"
            />
          </div>
        ))}



       
      <div expand='lg' light bgColor='light'>
        <div style={{ justifyContent: "center" }}>
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <p className='h1 hover-3 col-6 col-sm-3' style={{ color: "black" }}>ABOUT</p>
          </div>
        </div>

      </div>

      <div className='bsness-scope'>
        <div className='col-10 col-sm-5 bsness-scope-col'>
          <p className='bsness-disc'>We the Team popmak... are a bunch of committed entrepreneurs. Focusing on "co-creation" at all levels, we aim to bridge the gap between nature and consumers. Our team is committed to delivering the wonderful gifts that nature has in store, in form of healthy, nutritious and organic foods.The aim is to create an offering by combining the natural foods with modern techniques so as to enable the human kind to rejoice these gifts. POPMAK is one such creation.It is a healthy munching solution for all ages. In fact mothers have started giving it space in their kids lunch boxes. We are happy & sure to bring in such product line up which can add values in your life. We are just four years old company, aiming big to deliver and in this endeavor we will remain child like enthusiast for ever â€“Expect more surprises from us soon!!!! </p>
        </div>
        <div className='col-10 col-sm-5 bsness-scope-col'>
          {Aboutimage.Aboutimage.map((Aboutimage, index) => {
            return (
              <div key={"abouts" + index}>
                <img
                  className="d-block w-100 "
                  src={Aboutimage.image}
                  alt="images"
                />

              </div>
            )
          })}
        </div>
      </div>


      <div className='bsness-scope'>
        <div className='col-10 col-sm-5 bsness-scope-col'>
          <h3>BUSINESS SCOPE</h3>
          <p className='bsness-disc'>To carry on the business of process, produce, mix, roast, pack, preserve, freeze, extract, refine, manufacture, import, export, buy, sell, grind, trade and deal in processed foods, health foods, protein foods, food products, agro foods, fast foods, packed foods, nutrient, health and diet foods, extruded foods, precooked foods, canned foods, preserved foods, bakery products / drinks, extruded foods, confectionery items, cereals products and any other food products in and outside India.</p>
          <h3>Mission Statement</h3>
          <p className='bsness-disc'>Organization will be committed for providing Healthy, nutritious and Organic Raw & Processed Foods for all age groups in and beyond the national boundaries.</p>
          <h3>Vision Statement</h3>
          <p className='bsness-disc'>Company aims to create value for consumers by providing tasty, healthy and nutritious food simultaneously contributing towards enhancement of socio economic condition of all the STAKE HOLDERS in profitable and sustainable manner.</p>
        </div>
        <div className='col-10 col-sm-5 bsness-scope-col'>
          <h3>Company Goals and Objectives</h3>
          <h6 className='goal'>Goal :</h6>
          <p className='bsness-disc'>Epicenter of our existence defines our goal i.e. to be the most revered organization in the healthy snacks and organic food market over the period of 10 years.</p>
          <h6 className='goal'>Objective :</h6>
          <p className='bsness-disc'> 1) Foremost objective is to provide the nutritional healthy packaged food to Consumer. </p>
          <p className='bsness-disc'> 2) We aim to create values for all the associated stake holders.</p>
          <p className='bsness-disc'> 3) Introduce healthy foods from various regions and do innovative value addition in order to increase their reach by marketing them in different geographical market. where these products are less known thereby providing varied healthy food options to consumer. </p>
          <h3>Business Philosophy</h3>
          <p className='bsness-disc'>Co-creation This implies value should be created for the entire stake holders (owners, consumers, vendors, suppliers) in the business at all levels of operation.
          </p>
        </div>
      </div>
      <Slogan /> 
      <div expand='lg' light bgColor='light'>
        <div fluid style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div>
            <u>	<h4 style={{ fontWeight: "bold", margin: "20px", padding: "20px", color: "black" }}>We Have</h4></u>

          </div>
        </div>
      </div>
      <div className='row col-12'>


        <div className=" about-icons" style={{ display: "flex", textAlign: "center", marginTop: "2vw" }}>

          {aboutData.aboutData.map((aboutData, index) => {
            return (
              <div className='col-6 col-sm' key={"abs" + index} >
                <div className='about-image1'>
                  <img src={aboutData.image} alt={aboutData.image} className='hover about-image' />
                  <p>{aboutData.name}</p>
                </div>
              </div>
            )
          })}



        </div>



      </div>

    </div>
  )
}

export default About




