
import '../FranchiseEnquiry/Franchise.css';
import React,{useState} from 'react';
import axios from "axios";
import { 
    MDBInput,
    MDBBtn
  } from 'mdb-react-ui-kit';
  import vendor from '../../components/assets/frenchise.png'

function Franchise() {
    const[inputs,setinputs]=useState({
        fullName : "",
        businessName: "",
        email: "",
        phoneNumber: "",
        location: "",
        });

    const[msgDiv,setMsgDiv]=useState(false);
    const[msg,setMsg]=useState(false);

    const baseURL = process.env.REACT_APP_API_URL;
    const token = `Bearer ` + localStorage.getItem('token');
    const config = {
        headers: {
            'Authorization': token,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        }
    };

    const inputEvent= (event)=>{
       
        const name=event.target.name;
        const value=event.target.value;

        setinputs(prevState => ({
           ...prevState, [name]: value 
          }))

}

    const savefrenchise = async (e) => {
        e.preventDefault();
        
        if(inputs.phoneNumber.length < 10) {
            alert('Invalid Phonenumber')
            return
        }

        setMsgDiv(true);
        const bodyparams = {
            fullName: inputs.fullName,
            businessName: inputs.businessName,
            email:inputs.email,       
            phoneNumber:inputs.phoneNumber,
            location:inputs.location,
          }

        await axios
       .post(baseURL +"franchiseenquiry/register/",  
       bodyparams, config)
        .then(data =>{
        if(data.data.statuscode === "200"){     
                setinputs({
                    fullName:"",
                    businessName:"",
                    email:"",
                    phoneNumber:"",
                    location:"",
                   })
                   setMsg(true);
                   alert("Thank You for showing Interest in our company, we will contact you soon !!!")
                   window.location.href = '../'
             }
    })
  .catch(error => console.log(error));
  }
    

    return (
        <div className='row franchise-main'>
        <div className='franchise-left col-12 col-sm-6'>               
            <form  className='text-center container Franchise' onSubmit={(e) => savefrenchise(e)} >
                <h4 >Franchise Enquiry</h4>
                
                
                <div className='col-12 col-sm-8' style={{ margin: "20px" }} >
                    <div className=" col-sm" >
                        <MDBInput label='Name' v-model='name' name="fullName" wrapperClass='mb-4' value={inputs.fullName}  onChange={inputEvent} onKeyDown={e => {if(!(/[a-zA-Z ]/g).test(e.key)) e.preventDefault();}} required />

                    </div>
                    <div className=" col-sm" >
                        <MDBInput label='Business Name' v-model='Company Name' name="businessName" wrapperClass='mb-4' value={inputs.businessName}  onChange={inputEvent} required />

                    </div>
                    <div className=" col-sm" >
                        <MDBInput type='email' label='Email Address' v-model="email" name="email" wrapperClass='mb-4' value={inputs.email}  onChange={inputEvent} onKeyUp={(e) => e.target.value =  e.target.value.toLowerCase()} required/>

                    </div>
                    <div className=" col-sm" >
                        <MDBInput  label='Phone Number' v-model='number' wrapperClass='mb-4' name="phoneNumber" maxLength={10} value={inputs.phoneNumber}  onChange={inputEvent} onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} required />

                    </div>
                    <div className=" col-sm" >
                        <MDBInput label='Location' v-model='Location' wrapperClass='mb-4' name="location" value={inputs.location} onChange={inputEvent} required/>
                    </div>
                </div>
                { msgDiv ? <div className="thanks" style={{color:"red"}}>{msg ? "Thank You for showing Interest in our company, we will contact you soon !!!" : "Please wait..."}</div> : null }
                <div className="col-12 col-sm">
                    <MDBBtn type='submit'  style={{ backgroundColor: "#f44336", width: "30vw" }} block className='my-4'>
                        Send
                    </MDBBtn>
                </div>
            </form>                
        </div>
        <div className='franchise-right col-6'>
            <img className='franchise-img' src={vendor} alt='' />
        </div>
    </div>
    );
}

export default Franchise;