import React, { useState } from 'react';
import '../Footer/Footer.css';

export default function Footer({ cartItems }) {
  const [number, setNumber] = useState('');

  const handleInputChange = (e) => {
    setNumber(e.target.value);
  };

  const handleSignUp = async () => {
    const indianMobileNumberPattern = /^[789]\d{9}$/;
    if (!number) {
      alert('Please enter a number.');
      return;
    }

    if (!indianMobileNumberPattern.test(number)) {
      alert('Please enter a valid Indian mobile number.');
      return;
    }

    try {
      const response = await fetch('https://your-api-endpoint.com/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ number }),
        mode: 'no-cors' // Set the request mode to 'no-cors'
      });


      if (response.ok) {
        alert('Sign up successful!');
      } else {
        alert('Failed to sign up. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-section">
          <h3 className="footer-heading">POPMAK</h3>
          <div className="footer-divider"></div>
          <p className="footer-text">
            We believe the best way to deliver a great user experience is by deeply understanding what people want and love.
          </p>
        </div>

        <div className="footer-section">
          <h3 className="footer-heading">YOUR LINKS</h3>
          <div className="footer-divider"></div>
          <div className="footer-linkdiv">
            <div>
              <ul className="footer-links">
                <li><a href="/about" className="footer-link">About Us</a></li>
                <li><a href="/contact" className="footer-link">Contact Us</a></li>
                <li><a href="/shop" className="footer-link">Shop</a></li>
                <li><a href="/" className="footer-link">Members Corner</a></li>
              </ul>
            </div>
            <div>
              <ul className="footer-links">
                <li><a href="/privacypolicy" className="footer-link">Privacy Policy</a></li>
                <li><a href="/returnpolicy" className="footer-link">Return Policy</a></li>
                <li><a href="/" className="footer-link">Data Safety</a></li>
                <li><a href="/" className="footer-link">FAQ</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-section">
          <h3 className="footer-heading">CONTACT INFO</h3>
          <div className="footer-divider"></div>
          <p className="footer-contact">STK Food Processing Pvt. Ltd. Gat No-411, Plot-87, Uravade, Pune, Maharashtra - 412115</p>
          <p className="footer-contact">+91 9953191711</p>
          <p className="footer-contact">stkfoodprocessing@gmail.com</p>
          <div className="footer-social">
            <a href='/'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter m-2">
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
              </svg>
            </a>
            <a href='/'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook m-2">
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
              </svg>
            </a>
            <a href='/'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram m-2">
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
              </svg>
            </a>
            <a href='/'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-linkedin m-2">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </a>
          </div>
        </div>

        <div className="footer-section newsletter">
          <h3 className="footer-heading">NEWSLETTER</h3>
          <div className="footer-divider"></div>
          <p className="footer-text">Get all the best deals, sales and offers from the best online shopping from here. Sign up now!</p>
          <div className="footer-newsletter ">
            <input
              type="text"
              placeholder="Your Number..."
              className="footer-newsletter-input"
              value={number}
              onChange={handleInputChange}
              required
            />
            <button className="footer-newsletter-button" onClick={handleSignUp}>
              SIGN UP
            </button>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <nav className="footer-nav">
          <a href="/about" className="footer-nav-link">ABOUT US</a>
          <span className="footer-nav-divider">|</span>
          <a href="/contact" className="footer-nav-link">CUSTOMER SERVICE</a>
          <span className="footer-nav-divider">|</span>
          <a href="/privacypolicy" className="footer-nav-link">PRIVACY POLICY</a>
          <span className="footer-nav-divider">|</span>
          <a href="/" className="footer-nav-link">SITE MAP</a>
          <span className="footer-nav-divider">|</span>
          <a href="/returnpolicy" className="footer-nav-link">RETURNS POLICY</a>
          <span className="footer-nav-divider">|</span>
          <a href="/contact" className="footer-nav-link">CONTACT US</a>
        </nav>
      </div>

      <div className="footer-copyright">
        <p>© 2024 Copyright: <a href='https://popmak.in/'>popmak.in</a></p>
      </div>
    </div>
  );
}
