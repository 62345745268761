import React from 'react';
import Slider from '../../components/slider/Slider';
import Silederlow from '../../components/sliderlow/Silederlow';
import AboutSection from './AboutSection';
import NewArrival from './NewArrival';
import "./home.css"


function Home() {
  return (
    <>
   
    < Slider/>
    <Silederlow/>
    <div className="row col-12 slider-low-img" bgColor='light' >
    <AboutSection />
      <NewArrival />
    </div>
  
    </>
  )
}

export default Home