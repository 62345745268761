import React, { useEffect, useState } from 'react';
import {
    MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBTypography,
} from 'mdb-react-ui-kit';

import { CDBSidebarHeader } from 'cdbreact';
import '../OrderSummary/OrderSummary.css';
import AuthService from '../../servicecall/auth.service';


const OrderSummary = () => {

    if (!localStorage.getItem('token')) {
        window.location.href = '/login'
    }

    const fullName = useState(JSON.parse(localStorage.getItem('fullName')));
    const userDetails = useState(JSON.parse(localStorage.getItem('userDetails')));
    // const [userName, setuserName] = useState("Login Details");

    const [billingAddress, setBillingAddress] = useState([]);
    const [shippingAddress, setShippingAddress] = useState([]);

    // const checkLogin = async () => {
    //     if (localStorage.getItem('token')) { 
    //       await setuserName(localStorage.getItem('fullName').slice(1, -1));
    //     } else { 
    //       await setuserName("Login");
    //     }
    //   }

    const logoutButtonCall = async () => {
        AuthService.logout()
        // await checkLogin();
    }

    const getAddress = () => {
        AuthService.BillingAddressDetails()
            .then((res) => {
                setBillingAddress(res)
            })

        AuthService.ShippingAddressDetails()
            .then((res) => {
                setShippingAddress(res)
            })
    }

    useEffect(() => {
        getAddress()
    }, [])


    return (
        <div className='row '>
            <div className='order-sidebar col-3' >
                <div textColor="#fff" backgroundColor="white" className='col-12'>
                    <CDBSidebarHeader >
                        <div className='card profile-card'>
                            <div><img className='profile-img' src="../assets/profile.jpg" alt='profile POPMAK User' /></div>
                            <div className='profile-details'>
                                <p style={{ textAlign: "left" }}>{fullName}<br />{userDetails.email}</p>
                            </div>
                        </div>
                        <div className="sidebar-content">
                            <div className='card dashboard-card'>
                                <div className='dash-items'>
                                    <i className="fa fa-bars" aria-hidden="true"></i>
                                    <a className='black-link' href='/ordersummary'> Dashboard</a>
                                </div>
                                <div className='dash-items'>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <a className='black-link' href='/orderdetails'> Orders</a>
                                </div>
                                <div className='dash-items'>
                                    <i className="fa fa-address-card" aria-hidden="true"></i>
                                    <a className='black-link' href='/address'> Address Book</a>
                                </div>
                                {/* <div className='dash-items'>
                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                <a className='black-link' href='/address'> Shopping cart</a>
                            </div> */}
                                <div className='dash-items'>
                                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                                    <a className='black-link' href='/' onClick={logoutButtonCall}> Sign Out</a>
                                </div>
                            </div>
                        </div>
                    </CDBSidebarHeader>
                </div>
            </div>
            <div className='col-12 col-sm-9'>
                <div className="mx-auto mt-3" style={{ maxWidth: '900px' }}>
                    <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                        <MDBCol md="12" className="mb-4">
                            <MDBCard className="mb-4">
                                <MDBCardHeader className="py-3">
                                    <MDBTypography tag="h5" className="mb-0" style={{ color: "red" }}>Billing details</MDBTypography>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    {billingAddress.map((add) => {
                                        return (
                                            <>
                                                <table style={{ width: '100%' }}>
                                                    <tr>
                                                        <th>
                                                            Full Name :
                                                        </th>
                                                        <td>
                                                            {fullName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Company Name :
                                                        </th>
                                                        <td>
                                                            {add.companyName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Address :
                                                        </th>
                                                        <td>
                                                            {add.addressLine1}, {add.city}, {add.state} - {add.pincode}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Email Address :
                                                        </th>
                                                        <td>
                                                            {add.emailId}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Phone Number :
                                                        </th>
                                                        <td>
                                                            {add.phoneNumber}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Gst Number :
                                                        </th>
                                                        <td>
                                                            {add.gstNo}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </>
                                        )
                                    })}

                                </MDBCardBody>
                            </MDBCard>
                            {shippingAddress.map((add) => {
                                return (
                                    <MDBCard className="mb-4">
                                        <MDBCardHeader className="py-3">
                                            <MDBTypography tag="h5" className="mb-0" style={{ color: "red" }}>Shipping details</MDBTypography>
                                        </MDBCardHeader>
                                        <MDBCardBody>
                                            <>
                                                <table style={{ width: '100%' }}>
                                                    <tr>
                                                        <th>
                                                            Full Name :
                                                        </th>
                                                        <td>
                                                            {fullName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Company Name :
                                                        </th>
                                                        <td>
                                                            {add.companyName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Address :
                                                        </th>
                                                        <td>
                                                            {add.addressLine1}, {add.city}, {add.state} - {add.pincode}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Email Address :
                                                        </th>
                                                        <td>
                                                            {add.emailId}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Phone Number :
                                                        </th>
                                                        <td>
                                                            {add.phoneNumber}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Gst Number :
                                                        </th>
                                                        <td>
                                                            {add.gstNo}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </>
                                        </MDBCardBody>
                                    </MDBCard>
                                )
                            })}
                        </MDBCol>

                    </MDBRow>
                </div>
            </div>
        </div>

    )
}


export default OrderSummary