import React from 'react'
import {  CDBSidebarHeader} from 'cdbreact'; 
import AuthService from '../../servicecall/auth.service';
function UserLefNavbar() {

    const  fullName  = (localStorage) ? JSON.parse(localStorage.getItem('fullName')) : "" ;
    const userDetails = (localStorage) ?  JSON.parse(localStorage.getItem('userDetails')) : "";
    // const [isLoggin, setisLoggin] = useState(false);
    // const [userName, setuserName] = useState("Login Details");


    // const checkLogin = async () => {
    //     if (localStorage.getItem('token')) {
    //       await setisLoggin(true);
    //       await setuserName(localStorage.getItem('fullName').slice(1, -1));
    //     } else {
    //       await setisLoggin(false);
    //       await setuserName("Login");
    //     }
    //   }

      const logoutButtonCall = async () => {
        AuthService.logout()
        // await checkLogin();
      }

  return (
   <>
               <div className='order-sidebar col-3' >
                <div textColor="#fff" backgroundColor="white" className='col-12'>
                <CDBSidebarHeader >
                <div className='card profile-card'>
                                    <div><img className='profile-img' src="../assets/profile.jpg" alt='profile POPMAK User' /></div>
                                    <div className='profile-details'>
                                        <p style={{ textAlign: "left" }}>{fullName}<br />{userDetails.email}</p>
                                    </div>
                                </div>
                    <div className="sidebar-content">
                        <div className='card dashboard-card'>
                            <div className='dash-items'>
                                <i className="fa fa-bars" aria-hidden="true"></i>
                                <a className='black-link' href='/ordersummary'> Dashboard</a>
                            </div>
                            <div className='dash-items'>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <a className='black-link' href='/orderdetails'> Orders</a>
                            </div>
                            <div className='dash-items'>
                                <i className="fa fa-address-card" aria-hidden="true"></i>
                                <a className='black-link' href='/address'> Address Book</a>
                            </div>
                            {/* <div className='dash-items'>
                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                <a className='black-link' href='/address'> Shopping cart</a>
                            </div> */}
                            <div className='dash-items'>
                                <i className="fa fa-sign-out" aria-hidden="true"></i>
                                <a className='black-link' href='/' onClick={logoutButtonCall}> Sign Out</a>
                            </div>
                        </div>
                    </div>
                    </CDBSidebarHeader>
                </div>

            </div>
   </>
  )
}

export default UserLefNavbar