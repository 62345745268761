const Data = [
    {
        id: 1,
        slug: "black-paper-himalaya-salt",
        name: "Black Pepper & Himalayan Salt",
        image: "../Images/Black Paper.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
         
        taxpercent: 12.00,     
           description: "Made with good quality ground pepper and rock salt. Great fasting snack. Sugar Free.",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Black Paper.jpg",
            alttag: "Black Paper Himalaya Salt"
        },
        {
            id: "2",
            imagepath: "../Images/Black Paper_back.jpg",
            alttag: "Black Paper Himalaya Salt"
        },
        {
            id: "3",
            imagepath: "../Images/Black Paper_Bowl.jpg",
            alttag: "Black Paper Himalaya Salt"
        }]
    },
    {
        id: 2,
        slug: "black-salt",
        name: "Black Salt",
        image: "../Images/Black Salt.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
        taxpercent: 12.00,
        description: "Humble Makhana at its best!! You get to taste the real makhanas here. Lightly salted. Sugar free.",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Black Salt.jpg",
            alttag: "Black Salt"
        },
        {
            id: "2",
            imagepath: "../Images/Black Salt_back.jpg",
            alttag: "Black Salt"
        },
        {
            id: "3",
            imagepath: "../Images/Black Salt_Bowl.jpg",
            alttag: "Black Salt"
        }],
       
    },
    {
        id: 3,
        slug: "coriander-delight",
        name: "Coriander Delight",
        image: "../Images/Coriander Delight.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
        taxpercent: 12.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Coriander Delight.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "2",
            imagepath: "../Images/Coriander Delight_back.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "3",
            imagepath: "../Images/Coriander Delight_Bowl.jpg",
            alttag: "Image Alt tag"
        }]
    },
    {
        id: 4,
        slug: "sour-cream-n-onion",
        name: " Sour Cream n Onion",
        image: "../Images/Cream And Onion.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
        taxpercent: 12.00,
        description: "Kids Favorite!!! sweet ans salty flavor is widely loved by the lil champs!!!",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Cream And Onion.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "2",
            imagepath: "../Images/Cream And Onion_back.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "3",
            imagepath: "../Images/Cream And Onion_Bowl.jpg",
            alttag: "Image Alt tag"
        }]
    },
    {
        id: 5,
        slug: "heavenly-cheese",
        name: "Heavenly Cheese",
        image: "../Images/Hevenly cheez.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
        taxpercent: 12.00,
        description: "Kids Favorite!!! sweet ans salty flavor is widely loved by the lil champs!!!",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Hevenly cheez.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "2",
            imagepath: "../Images/Hevenly cheez_back.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "3",
            imagepath: "../Images/Hevenly cheez_Bowl.jpg",
            alttag: "Image Alt tag"
        }]
    },
    {
        id: 6,
        slug: "indian-spices",
        name: "Indian Spices",
        image: "../Images/Indian Spices.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
        taxpercent: 12.00,
        description: "Makhana roasted and tossed in blend of Indian spices. Medium spicy and is loved by all age groups. No unnessary additives like maltodextrin, HVP, E631, E627, etc.",
        images: [{
            id: "1",
            imagepath: "../Images/Indian Spices.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "2",
            imagepath: "../Images/Indian Spices_back.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "3",
            imagepath: "../Images/Indian Spices_Bowl.jpg",
            alttag: "Image Alt tag"
        }]
    },
    {
        id: 7,
        slug: "mumbai-chat",
        name: "Mumbai Chat",
        image: "../Images/Mumbai chat.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
        taxpercent: 12.00,
        description: "So natural and sugar free. Made in natural rock salt. No table salt. No asafoetida. Gluten Free.",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Mumbai chat.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "2",
            imagepath: "../Images/Mumbai chat_back.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "3",
            imagepath: "../Images/Mumbai chat_Bowl.jpg",
            alttag: "Image Alt tag"
        }]
    },
    {
        id: 8,
        slug: "peri-peri",
        name: "Peri Peri",
        image: "../Images/Peri Peri.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
        taxpercent: 12.00,
        description: "Makhana roasted and tossed in spicy Peri Peri seasoning. Satisfyingly spicy:) No unnessary additives like maltodextrin, HVP, E631, E627, etc.",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Peri Peri.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "2",
            imagepath: "../Images/Peri Peri_back.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "3",
            imagepath: "../Images/Peri Peri_Bowl.jpg",
            alttag: "Image Alt tag"
        }]
    },
    {
        id: 9,
        slug: "spanish-tomato",
        name: "Spanish Tomato",
        image: "../Images/Spanish Tomato.jpg",
        price: 150, basePrice : 133.93,
        quantity: 1, weight : '75gm',
        taxpercent: 12.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Spanish Tomato.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "2",
            imagepath: "../Images/Spanish Tomato_back.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "3",
            imagepath: "../Images/Spanish Tomato_Bowl.jpg",
            alttag: "Image Alt tag"
        }]
    }, {
        id: 10,
        slug: "pink-salt",
        name: "Pink Salt with Desi Ghee",
        image: "../Images/Pink Salt.jpg",
        price: 160,basePrice : 142.91,
        quantity: 1, weight : '70gm',
        taxpercent: 12.00,
        description: "Pink Salt with Desi Ghee",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Pink Salt.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "2",
            imagepath: "../Images/Pink Salt_back.jpg",
            alttag: "Image Alt tag"
        },
        {
            id: "3",
            imagepath: "../Images/Pink Salt_Bowl.jpg",
            alttag: "Image Alt tag"
        }]
    },
    {
        id: 11,
        slug: "Customized-Makhana",
        name: "Customized Makhana",
        image: "../Images/product2.png",
        price: 160, basePrice : 142.91,
        quantity: 1, weight : '70gm',
        taxpercent: 12.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/product2.png",
            alttag: "Image Alt tag"
        },
        // {
        //     id: "2",
        //     imagepath: "../Images/product2.jpg",
        //     alttag: "Image Alt tag"
        // },
        // {
        //     id: "3",
        //     imagepath: "../Images/product2.jpg",
        //     alttag: "Image Alt tag"
        // }
    ],
        customizedPacks : [{
            label : "Made In : ",
            name : "Filtered",
            value : [{value : "OLIVE OIL"},{ value :  "GHEE"}],
            selectedfilter:""
        },
        {
            label : "Type Of Salt : ",
            name : "Salt",
            value : [{value : "BLACK"},{ value :  "PINK"}],
            selectedsalt:""
        }]
    },
    {
        id: 12,
        slug: "Chana-Sattu",
        name: "Chana Sattu",
        image: "../Images/Chana Sattu.png",
        price: 110, basePrice : 104.76,
        quantity: 1, weight : '80gm',
        taxpercent: 5.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Chana Sattu.png",
            alttag: "Chana Sattu"
        },
        {
            id: "2",
            imagepath: "../Images/Chana Sattu_Back.png",
            alttag: "Image Alt tag"
        },
        // {
        //     id: "3",
        //     imagepath: "../Images/product2.jpg",
        //     alttag: "Image Alt tag"
        // }
    ],
    },
    {
        id: 13,
        slug: "POPMAK-RAGI-MILLET-DOSA-MIX",
        name: "POPMAK RAGI MILLET DOSA MIX",
        image: "../Images/DSC_6457.JPG",
        price: 125, basePrice : 119.05,
        quantity: 1, weight : '200gm',
        taxpercent: 5.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/DSC_6457.JPG",
            alttag: "POPMAK RAGI MILLET DOSA MIX"
        }  
    ],
    },{
        id: 14,
        slug: "POPMAK-ROASTED-CHANA-PERI-PERI",
        name: "POPMAK ROASTED CHANA PERI PERI",
        image: "../Images/DSC_6458.JPG",
        price: 70, basePrice : 66.67,
        quantity: 1, weight : '200gm',
        taxpercent: 5.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/DSC_6458.JPG",
            alttag: "POPMAK ROASTED CHANA PERI PERI"
        }  
    ],
    },
    {
        id: 15,
        slug: "POPMAK-JOWAR-PUFF-PERI-PERI",
        name: "POPMAK JOWAR PUFF PERI PERI ",
        image: "../Images/Jowar Puff Front Updated.jpg",
        price: 50, basePrice : 47.62,
        quantity: 1, weight : '75gm',
        taxpercent: 5.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Jowar Puff Front Updated.jpg",
            alttag: "POPMAK JOWAR PUFF PERI PERI "
        }  ,
        {
            id: "2",
            imagepath: "../Images/Jowar Puff Back Updated.jpg",
            alttag: "POPMAK JOWAR PUFF PERI PERI "
        }  ,
    ],
    }, {
        id: 16,
        slug: "POPMAK-TAAL-MAKHANA-200mg",
        name: "POPMAK TAAL MAKHANA",
        image: "../Images/raw makhana updated.jpg",
        price: 260, basePrice : 247.55,
        quantity: 1, weight : '200gm',
        taxpercent: 5.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/raw makhana updated.jpg",
            alttag: "POPMAK  TAAL MAKHANA"
        }  ,
        {
            id: "2",
            imagepath: "../Images/raw makhana updated.jpg",
            alttag: "POPMAK  TAAL MAKHANA"
        }  ,
    ],
    },{
        id: 17,
        slug: "POPMAK-TAAL-MAKHANA-100mg",
        name: "POPMAK TAAL MAKHANA",
        image: "../Images/Raw Makhana 100 g front new.jpg",
        price: 140, basePrice : 133.40,
        quantity: 1, weight : '100gm',
        taxpercent: 5.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/Raw Makhana 100 g front new.jpg",
            alttag: "POPMAK  TAAL MAKHANA"
        }  ,
        {
            id: "2",
            imagepath: "../Images/Raw Makhana 100 g Back new.jpg",
            alttag: "POPMAK  TAAL MAKHANA"
        }  ,
    ],
    },
    {
        id: 18,
        slug: "POPMAK-HORSE-GRAM-MILLET-DOSA-MIX",
        name: "POPMAK HORSE GRAM MILLET DOSA MIX",
        image: "../Images/DSC_6459.JPG",
        price: 135, basePrice : 128.57,
        quantity: 1, weight : '500gm',
        taxpercent: 5.00,
        description: "",
        sku: "",
        images: [{
            id: "1",
            imagepath: "../Images/DSC_6459.JPG",
            alttag: "POPMAK HORSE GRAM MILLET DOSA MIX"
        }  
    ],
    },
]



export default Data;