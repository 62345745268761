import React, { useState } from 'react';
import { CDBSidebarHeader } from 'cdbreact';
import '../OrderSummary/OrderSummary.css';
import AuthService from '../../servicecall/auth.service';


const OrderSummary = ({ cartItems }) => {

    if(!localStorage.getItem('token')){
        window.location.href= '/login'
    }

    const  fullName = useState(JSON.parse(localStorage.getItem('fullName')));
    const  userDetails  = useState(JSON.parse(localStorage.getItem('userDetails')));
 

      const logoutButtonCall = async () => {
        AuthService.logout() 
      }


    return (
        <div className='row'>
            <div className='order-sidebar col-3' >
                <div textColor="#fff" backgroundColor="white" className='col-12 carddiv'>
                    <CDBSidebarHeader >
                    <div className='card profile-card'>
                        <div><images className='profile-img' src="../assets/profile.jpg" alt='profile image' /></div>
                        <div className='profile-details'>
                            <p style={{ textAlign: "left", color: "white" }}>{fullName}<br />{userDetails.email}</p>
                        </div>
                    </div>
                    <div className="sidebar-content">
                        <div className='card dashboard-card'>
                            <div className='dash-items'>
                                <i className="fa fa-bars" aria-hidden="true"></i>
                                <a className='black-link' href='/ordersummary'> Dashboard</a>
                            </div>
                            <div className='dash-items'>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <a className='black-link' href='/orderdetails'> Orders</a>
                            </div>
                            <div className='dash-items'>
                                <i className="fa fa-address-card" aria-hidden="true"></i>
                                <a className='black-link' href='/address'> Address Book</a>
                            </div>
                            <div className='dash-items'>
                                <i className="fa fa-sign-out" aria-hidden="true"></i>
                                <a className='black-link' href='/' onClick={logoutButtonCall}> Sign Out</a>
                            </div>
                        </div>
                    </div>
                    </CDBSidebarHeader>
                </div>
            </div>
            
        </div>
    )
}

export default OrderSummary