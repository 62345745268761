import React from "react";

import Home from "../pages/Home/Home";

function Logout() {
    localStorage.clear();
    return(
    <>
    <Home />
    </>
    );
}
export default Logout;