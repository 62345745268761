import React from 'react'
import {  Link } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCheckbox, MDBBtn , MDBRow, MDBCol, MDBIcon, MDBInput,MDBTextArea, MDBTypography} from "mdb-react-ui-kit";
import { useState , useEffect } from 'react';
import AuthService from '../../servicecall/auth.service';
// import axios from "axios";
// import Address from '../../components/front/Cart/address';
// import CartLogin from '../../authentication/cartLogin';
import CartSummary from '../../components/front/Cart/cartSummary';

function Checkout({summary, cartItems, freeItemCart}) {
    // const navigate = useNavigate();
    const [isLoggin, setisLoggin] = useState(false);
    const [userName1, setuserName1] = useState("Login Details");
    // const [shouldRedirect, setShouldRedirect] = useState(false);
    const [fullName, setFullName] = useState('')
    const [addressLine1 , setAddressLine1] = useState('') 
    const [city , setCity] = useState('') 
    const [state , setState] = useState('') 
    const [pincode , setPincode] = useState('') 
    const [email , setemail] = useState('') 
    const [phoneNumber , setPhoneNumber] = useState('') 
    const [companyName , setCompanyName] = useState('') 
    const [gstNo , setGstNo] = useState('') 
    const [stateCode , setStateCode] = useState('')
    const [role , setRole] = useState('')
     const [shippingAddress, setshippingAddress] = useState('');
     const [shippingCity, setshippingCity ] = useState('')
     const [shippingName, setShippingName] = useState('')
     const [shippingPincode, setshippingPincode] = useState('')
      const [shippingInstructions,] = useState('');
      const [username, setUsername] = useState("");
      const [password, setPassword] = useState("");
      const[Message,setMessage]=useState();
      const [isInvalid, setIsInvalid] = useState(false);


const updateFullName = (e) => {
  setFullName(e.target.value)
}
const getpincodedetails = (e) => {
  setPincode(e.target.value)
  setcityState(e.target.value)

}
const setcityState= async ( value) =>{
  await AuthService.pincodedetails(value)
  .then((res) => {
    if(res.length !== 0){
      setState(res[0].statename)
      setCity(res[0].area)
  }})
}

const [visible, ] = useState(false);
    const [setAddressDetails] = useState([]);

    const getBillingAddressDetails = async () => {
      await AuthService.BillingAddressDetails()
      .then(
        async(res) => {
          console.log("res", res.length > 0)
          if( res.length > 0){
          // console.log("Billing Address Details", res? res[0].gstNo)
          setGstNo(res[0].gstNo)
          setAddressLine1(res[0].addressLine1) 
          setemail(res[0].emailId)
          setPincode(res[0].pincode)
          setPhoneNumber(res[0].phoneNumber)
          setCompanyName(res[0].companyName)
          setStateCode(res[0].stateCode)
          setcityState(res[0].pincode)

          }
        }
      )
    }
    const getShippingAddress = async () => {
    await AuthService.ShippingAddressDetails()
      .then(async (res) => {
      //  alert("res user address Details", res)
       await setAddressDetails(res)
      })
    }
    
    useEffect(() => {         
     checkLoginDetails();
      if(summary && (summary.subtotalPrice === 0.00)){
       window.location.href = "../cart"
      }
    },)
    async function checkLoginDetails() { 
        const userIdData = localStorage.getItem('userId');        
      const userId1 = userIdData ? JSON.parse(userIdData) : null;
        if (localStorage.getItem('token')) {  
          setisLoggin(true); 
          await AuthService.checkUserToken(userId1).then((data)=>{
            if(data === "200"){ 
              setisLoggin(true);
            }else { 
              setisLoggin(false);
            }
          })         
      await getBillingAddressDetails();
      await getShippingAddress();
      const userIdDataFullName = localStorage.getItem('fullName');
      const userFullName = userIdDataFullName ?  userIdDataFullName  : null;
          setuserName1(userFullName)     
          // setFullName(userFullName)              

        } else {
          setisLoggin(false);
        }
    }

      const logoutButtonCall = async () => {        
        await setuserName1("Login");
        await AuthService.logout()
        setisLoggin(false);
      }


      // useEffect(() => {
      //   if (shouldRedirect) navigate("/cart");
      // }, [shouldRedirect]);

      useEffect(() => {
        if (visible) {
          setshippingAddress(addressLine1);
          setshippingCity(city);
          setshippingPincode(pincode);
          setShippingName(fullName);
        }
      }, [visible, addressLine1, city, fullName, pincode]);
      


      const handleLoginSubmit = async (e) => {
        e.preventDefault();
        await AuthService.login(username, password).then(async (data) => {
          if (localStorage.getItem('token') ==="" ) {
setMessage("Please Enter valid Username and Password")
setIsInvalid(true)
          }
        })
       await checkLoginDetails()
      }
  return (
    <div>
      <>
            <MDBCard className="mb-4">
                <MDBCardHeader className="py-3">
                <MDBTypography tag="h5" className="mb-0" style={{ fontWeight: "bold" , display:'flex', justifyContent:'space-between' }}>
                  Hello {userName1},
                  {isLoggin && (
                  <p style={{display:'flex', gap:'10px', alignItems:'center' }} onClick={logoutButtonCall}>
                    If not You,
                    <MDBIcon fas icon="sign-out-alt" style={{cursor:'pointer'}}  />
                  </p>
                  )}
                </MDBTypography>
                </MDBCardHeader>
                {!isLoggin && (<MDBCardBody>
                {/* <CartLogin onSuccess={checkLoginDetails}  /> */}
                {/* <CartLogin  />  */}

                <div className={(isInvalid) ? 'invalid-msg show' : 'invalid-msg'}> {Message}</div>
        <div  className='mx-auto'>
        <MDBCardBody  lg={4} xl={4}  sm={12} md={12} className='d-flex flex-column cart-login'>        
          <p className="text-white-50">Please enter your login and password!</p>
          <MDBInput wrapperClass='mb-4 w-100' label='UserName'   type='text' size="lg" onChange={(e) => setUsername(e.target.value)}/>
          <MDBInput wrapperClass='mb-4 w-100' label='Password'   type='password' size="lg"   onChange={(e) => setPassword(e.target.value)} />
          <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4' label='Remember password' />
          {/* {isInvalid && <p>{Message}</p> } */}
          <MDBBtn size='lg'  className="hideLogin" style={{ background: "red", fontWeight: "bold" ,}} type="submit" onClick={(e)=> {handleLoginSubmit(e)}}  >
          Login
          </MDBBtn>
          <p className="mb-5 pb-lg-2" style={{ color: '#393f81', margin: "15px" }}>
            <Link to='/register'>Register</Link>
            </p>
      </MDBCardBody>
      </div>

                </MDBCardBody>)}
            </MDBCard>
            <MDBCard >
                <MDBCardHeader className="py-3">
                <MDBTypography tag="h5" className="mb-0" style={{ fontWeight: "bold" }}>Address Details
                </MDBTypography>
                </MDBCardHeader>
                {isLoggin && (<><MDBCardBody className='checkout-address'>
                <MDBRow className="cart-info">
                  <MDBCol  lg={8} md={12} sm={12} xs={12} className="bottom" >

          <MDBCard>
                <MDBCardBody>
                <MDBRow style={{display:'flex' , flexDirection:'column'}}>
                  <MDBCol className='text-center text-md-start d-flex flex-column justify-content-center clearfix'>
                    <MDBCard>
            {/* <MDBCardHeader className="py-3">
              <MDBTypography tag="h5" className="mb-0" style={{ color: "red" }}>Address details</MDBTypography>
            </MDBCardHeader> */}
            <MDBCardBody>
              {/* <form key={localStorage.getItem('userId')} > */}
                    <>
                  <MDBRow className="mb-4">
                  <MDBCol>
                    <MDBInput label='Full name' type='text' value={fullName} onChange={updateFullName} required />
                  </MDBCol>
                    </MDBRow>
                    <MDBInput label='Address' type='text' className="mb-4" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} required />
                    <MDBRow>
                    <MDBCol>
                    <MDBInput label='Pincode' type='text' className="mb-4" value={pincode} onChange={getpincodedetails} required/>
                    </MDBCol>
                      <MDBCol>
                    <MDBInput label='City' type='text' className="mb-4" value={city} onChange={(e) => setCity(e.target.value)} required />
                    </MDBCol>
                    <MDBCol>
                    <MDBInput label='State' type='text' className="mb-4" value={state} onChange={(e) => setState(e.target.value)} required />  
                    </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                    <MDBInput label='Email' type='text' className="mb-4" value={email} onChange={(e) => setemail(e.target.value)} required/>
                    </MDBCol>
                    <MDBCol>
                    <MDBInput label='Phone' type='number' className="mb-4" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required/>          
                    </MDBCol>
                    </MDBRow>
                    
                    <MDBInput label='Company name' type='text' className="mb-4" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required/>
                    <MDBRow>  
                      <MDBCol>
                    <MDBInput label='Gst No' type='text' className="mb-4" value={gstNo} onChange={(e) => setGstNo(e.target.value)} required/>
                    </MDBCol>
                    <MDBCol>
                    <MDBInput label='State Code' type='text' className="mb-4" value={stateCode} onChange={(e) => setStateCode(e.target.value)} required />
                    </MDBCol>
                    </MDBRow>
                    <MDBTextArea label='Billing Instructions' rows={4} className="mb-4"  value={role} onChange={(e) => setRole(e.target.value)}  />
                </>
              {/* </form> */}
            </MDBCardBody>
    </MDBCard>
                  </MDBCol>
                  <MDBCol >
                        {/* <MDBCard >
            <MDBCardHeader className="py-3">
              <MDBTypography tag="h5" className="mb-0" style={{ color: "red" }}>Shipping details</MDBTypography>
            </MDBCardHeader>
            <MDBCardBody className='p-5'>
            <label lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <MDBRadio name='flexRadioDefault' onClick={() => setVisible(false)} id='flexRadioDefault1' defaultChecked />
        Address same as above
      </label>
      <label lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <MDBCardText style={{paddingLeft:'25px' ,textAlign: 'left'}} > {addressLine1},{city},{state},{pincode} </MDBCardText>
          </label>
            {addressDetails.map((address, index) => {
            
              return(
                <div key={index}>
                  <label lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      
                  <MDBRadio name='flexRadioDefault' id='flexRadioDefault1' label={fullName} onClick={() => {setVisible(false);    setshippingAddress(address.addressLine1)
         setshippingCity(address.city)
         setshippingPincode(address.pincode)
         setShippingName(fullName) } } />
                
                  <MDBCardText style={{paddingLeft:'25px' ,textAlign: 'left'}} > {address.addressLine1}, {address.city}, {address.state} - {address.pincode} </MDBCardText>
                </label>
                </div>
              )
            })}
             <label lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <MDBRadio name='flexRadioDefault' onClick={() => setVisible(true)} id='flexRadioDefault1' defaultChecked />
        Create New
      </label>
       {   visible &&  <p>   
         <MDBInput wrapperClass='mb-4' label='Name' name="shippingName"  value={shippingName} onChange={(e)=> { setShippingName(e.target.value)}} />
         
         <MDBInput wrapperClass='mb-4' label='address' name="shippingAddress" type='address' value={shippingAddress} onChange={(e)=> { setshippingAddress(e.target.value)}}  />
         <MDBRow className="mb-4">
                  <MDBCol>
                    <MDBInput label='City' type='text' name="shippingCity" value={shippingCity} onChange={(e)=> { setshippingCity(e.target.value)}}   />
                  </MDBCol>
                  <MDBCol>
                    <MDBInput label='Pincode' type='number' name='shippingPincode'  value={shippingPincode} onChange={(e)=> { setshippingPincode(e.target.value)}}   />
                  </MDBCol>
                </MDBRow>
         <MDBTextArea label='Shipping Instructions' rows={3} className="mb-2"  
         value={shippingInstructions}
        onChange={(e) => {
          setShippingInstructions(e.target.value);
        }}/></p>
}
         </MDBCardBody>
         </MDBCard> */}
                  </MDBCol>
                  
                </MDBRow>
                </MDBCardBody>
                </MDBCard>
                    </MDBCol>
                    <MDBCol  lg={4} md={12} sm={12} xs={12} className="mb-lg-0 product-info" >
                  <CartSummary  summaryDetails={summary}  cartItems={cartItems} freeItemCart={freeItemCart} 
                  address={{addressLine1:addressLine1,city:city,state:state, pincode:pincode,email:email,phoneNumber:phoneNumber,companyName:companyName,gstNo:gstNo,stateCode:stateCode,role:role}} 
                  shippingAddressDetails = {{shippingName, shippingAddress, shippingCity, shippingPincode, shippingInstructions}}
                   />
                  </MDBCol>
                  </MDBRow>
                </MDBCardBody>
                 </>
                
                )}
            </MDBCard>
        </>
              
    </div>
  )
}

export default Checkout
