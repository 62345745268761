import '../Navbar/Navbar.css';
import { useEffect, useState } from 'react';
import popmakicon from '../../components/assets/popmakicon.png'
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBIcon, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBBadge, MDBCollapse } from 'mdb-react-ui-kit';
import Dropdown from 'react-bootstrap/Dropdown';
import AuthService from '../../servicecall/auth.service';
import { Link } from 'react-router-dom';

export default function App({ cartItems }) {
  const [showBasic, setShowBasic] = useState(false);
  const [isLoggin, setisLoggin] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // Event handlers for mouse enter and mouse leave
  // const handleMouseEnter = () => setIsHovered(true);
  // const handleMouseLeave = () => setIsHovered(false);

  const checkLogin = async () => {
    if (localStorage.getItem('token')) {
      await setisLoggin(true);
    } else {
      await setisLoggin(false);
    }
  }

  const logoutButtonCall = async () => {
    AuthService.logout()
    await checkLogin();
  }

  useEffect(() => {
    checkLogin()
  })

  return (
    <MDBNavbar className='MDBNavbar navbar' style={{ position: "sticky", zIndex: "1" }} expand='lg' light >
      <MDBContainer fluid className='container'>
        <div className='nav-icon'>
          <div><MDBNavbarBrand href='/'  ><img src={popmakicon} alt=''
            className='nav-icon1' /></MDBNavbarBrand></div>
        </div>

        <MDBCollapse navbar show={showBasic} className='collapse-nav' style={{ textAlign: "right", paddingRight: "25px" }} >
          <MDBNavbarNav right className='mr-auto mb-2 mb-lg-0'>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-buttons" href='/'  style={{color : 'black'}}>
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-buttons" href='/shop'  style={{color : 'black'}}>Shop</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-buttons" a href='/about'  style={{color : 'black'}}>About</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-buttons" href='/contact'  style={{color : 'black'}}>Contact</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className='nav-hide'>
              <MDBNavbarLink className="nav-buttons" href='/vendor'  style={{color : 'black'}}>Vendor Enquiry</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className='nav-hide'>
              <MDBNavbarLink className="nav-buttons" href='/franchise'  style={{color : 'black'}}>Franchise Enquiry</MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>

        
        <MDBNavbarBrand className='cart-icon' href='/cart' >
          <i className="fa fa-shopping-cart nav" aria-hidden="true"  ></i>
          <MDBBadge color='danger' light pill className='position-relative translate-middle'>
            {cartItems.length === 0 ? "" : cartItems.length}
          </MDBBadge>
        </MDBNavbarBrand>
         

        {isLoggin &&
          (<Dropdown show={isHovered} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}   >
            <Dropdown.Toggle variant="" id="dropdown-basic" style={{boxShadow:'none'}}>
            <i className="fa fa-user" aria-hidden="true"></i>
            </Dropdown.Toggle>
           
            <Dropdown.Menu   >
              <Dropdown.Item href="/ordersummary"><i className="fa fa-bars" aria-hidden="true"></i> Dashboard</Dropdown.Item>
              <Dropdown.Item href="/orderdetails"><i className="fa fa-star" aria-hidden="true"></i> Orders</Dropdown.Item>
              <Dropdown.Item href="/address"><i className="fa fa-address-card" aria-hidden="true"></i> Address Book</Dropdown.Item>
              <Dropdown.Item href="/" onClick={logoutButtonCall}><i className="fa fa-sign-out" aria-hidden="true"></i> Sign Out</Dropdown.Item>
            </Dropdown.Menu>
 
          </Dropdown>)
        }

        {!isLoggin &&
          (<Link to={'/login'}>
            <Dropdown >
            <Dropdown.Toggle variant="" id="dropdown-basic" style={{boxShadow:'none'}}>
            <i className="fa fa-user" aria-hidden="true"></i>
            </Dropdown.Toggle>
          </Dropdown>
          </Link>)
        }
 
        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowBasic(!showBasic)}>
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
      </MDBContainer>
    </MDBNavbar>
  );
}
