import React, { useEffect } from 'react'
import {
  MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBBtn, MDBIcon,
  MDBCardHeader, MDBTypography
} from 'mdb-react-ui-kit';
import './productDetails.css'
import MagnifyImage from './magnifyImage';
import productData from '../../Data/ProductData'
import Carsoul from './carsoul';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'


const ProductDetails = ({ handleAddProduct, handleRemoveProduct, cartItems }) => {

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [cartbtn, setCartbtn] = useState('');
  const [cartbtnColor, setCartbtnColor] = useState('');

  // const [filterType, setfilterType] = useState("");
  // const [saltType, setsaltType] = useState("");

  const [imagePath, setImagePath] = useState()

  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (shouldRedirect) navigate("/cart");
  }, [shouldRedirect, navigate]);


  const handleBuyNow = async (obj) => {
    await handleAddProduct(obj)
    await setShouldRedirect(true)
  }
  const onChangesettype = (event, obj) => {
    if (event.target.name === 'Filtered') {
      // setfilterType(event.target.value);
      obj.customizedPacks = obj.customizedPacks.map(cartItem => ({
        ...cartItem, // Keep all old properties
        selectedfilter: event.target.value // Add quantity
      }))
      // console.log("sdssdsd"+JSON.stringify(obj));
    } else if (event.target.name === 'Salt') {
      obj.customizedPacks = obj.customizedPacks.map(cartItem => ({
        ...cartItem, // Keep all old properties
        selectedsalt: event.target.value // Add quantity
      }))
      // setsaltType(event.target.value);
    }

  }

  const filtered = productData.filter(obj => obj.slug === params.product);
  const productExist = cartItems.filter(item => item.id === filtered[0].id).map(item => item.id === filtered[0].id ? true : false);



  function getImages(image) {
    setImagePath(image)
  }


  useEffect(() => {
    if (productExist[0]) {
      setCartbtn('Added to Cart');
      setCartbtnColor('green');
    } else {
      setCartbtn('Add to Cart');
      setCartbtnColor('red');
    }
  }, [handleAddProduct, productExist]);
  


  return (
    <>
      {filtered.map(obj => {

        const shareURL = encodeURIComponent('https://pomak.in/details/' + obj.slug); // Replace with your actual URL
        const shareText = encodeURIComponent('Check out this link:');

        const linkedinShareLink = () => {
          const linkedinShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}`;
          window.open(linkedinShareLink, '_blank');
        };
        const twitterShareLink = () => {
          const twitterShareLink = `https://twitter.com/intent/tweet?text=${shareText}&url=${shareURL}`;
          window.open(twitterShareLink, '_blank');
        };
        const facebookShareLink = () => {
          const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}`;
          window.open(facebookShareLink, '_blank');
        };
        const whatsappShareLink = () => {
          const whatsappShareLink = `https://api.whatsapp.com/send?text=${shareText} ${shareURL}`;
          window.open(whatsappShareLink, '_blank');
        };
        const handleInstagramShare = () => {
          const instagramShareURL = `https://www.instagram.com/share?url=${shareURL}&caption=${shareURL}`;
          window.open(instagramShareURL, '_blank');
        };
        return (
          <>
            <MDBRow style={{
              marginTop
                : "20px"
            }}>
              <MDBCol sm='4'>
                <MDBCard style={{ display: "flex", alignSelf: "center", maxWidth: "100%" }}>
                  <MDBCardBody >
                    {/* <MDBCardTitle> {obj.slug}</MDBCardTitle> */}
                    <MDBCol md={12}>
                      <div className=" col-md-9" style={{ marginBottom: "10px" }} >
                        <MagnifyImage style={{ position: "fixed" }} image={(imagePath === undefined) ? obj.image : imagePath} />
                      </div>
                      <Carsoul images={obj.images} sendImage={getImages} />
                    </MDBCol>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol sm='5'>
                <MDBCard>
                  <MDBCardBody style={{ marginRight: "auto" }}>
                    <MDBCardTitle>{obj.name}</MDBCardTitle>
                    <hr />
                    <h6 style={{ display: "inline" }}>CATEGORY: Naturals</h6>
                    <h6>AVAILABILITY:<b> Available </b> </h6>

                    {
                      obj.customizedPacks ?
                        obj.customizedPacks.map(objval => {
                          return (
                            <div>
                              {/* <div onChange={onChangesettype} className='row'> */}
                              <div onChange={event => onChangesettype(event, obj)} className='row'>
                                <div className='col-4'>
                                  {objval.label}
                                </div>
                                <div className='col-8'>
                                  {objval.value.map(objvaldata => {
                                    return (
                                      <> &nbsp;<input type="radio" value={objvaldata.value} name={objval.name} />  &nbsp;{objvaldata.value}</>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          )


                        }) : ""



                    }

                    <div>
                      <h6>Net Weight:<b> {obj.weight}</b></h6>
                      <h6>TAG:<b> Salty</b></h6>
                      <hr />
                      <h6 style={{ color: "#000" }}>Price: ₹ {((obj.price) / (1 + (obj.taxpercent / 100))).toFixed(2)} <span style={{ fontSize: '10px' }}> excluding GST @ {obj.taxpercent}% :- ₹ {((obj.price) - ((obj.price) / (1 + (obj.taxpercent / 100)))).toFixed(2)}</span></h6>
                      <h4 style={{ color: "red" }}>Total: ₹ {obj.price} <span style={{ fontSize: "12px" }}> inclusive of  {obj.taxpercent}% GST ( ₹ {((obj.price) - ((obj.price) / (1 + (obj.taxpercent / 100)))).toFixed(2)}) </span></h4>
                    </div>

                    <hr />

                    <div className='row' style={{ display: "flex" }}>
                      <MDBBtn style={{ width: "48%", margin: "auto", marginTop: "10px", backgroundColor: cartbtnColor }} className='cartbtn' onClick={() => handleAddProduct(obj)}>{cartbtn}</MDBBtn>

                      <MDBBtn className="ad-cart" style={{ width: "48%", margin: "auto", marginTop: "10px", marginBottom: "10px" }} onClick={() => { handleBuyNow(obj) }}>Buy Now</MDBBtn>
                    </div>
                    <hr />


                    <MDBCardHeader><h4 className='fontfamily' style={{ fontWeight: "bold" }}>Description</h4></MDBCardHeader>
                    <MDBCardBody>
                      <MDBTypography blockquote className='mb-0'>
                        <ul><li className='fontfamily'>{obj.description}</li></ul>

                      </MDBTypography>
                    </MDBCardBody>
                    <hr />
                    <h5 style={{ fontWeight: "Bold" }}>Share on :</h5>
                    <section className='mb-4'>
                      <MDBBtn
                        floating
                        className='m-1'
                        style={{ backgroundColor: '#3b5998' }}
                        onClick={facebookShareLink}
                        role='button'
                      >
                        <MDBIcon fab icon='facebook-f' />
                      </MDBBtn>

                      <MDBBtn
                        floating
                        className='m-1'
                        style={{ backgroundColor: '#55acee' }}
                        onClick={twitterShareLink}
                        role='button'
                      >
                        <MDBIcon fab icon='twitter' />
                      </MDBBtn>

                      <MDBBtn
                        floating
                        className='m-1'
                        style={{ backgroundColor: '#25d366' }}
                        onClick={whatsappShareLink}
                        role='button'
                      >
                        <i className="fab fa-whatsapp"></i>

                      </MDBBtn>
                      <MDBBtn
                        floating
                        className='m-1'
                        style={{ backgroundColor: '#ac2bac' }}
                        onClick={handleInstagramShare}
                        role='button'
                      >
                        <MDBIcon fab icon='instagram' />
                      </MDBBtn>

                      <MDBBtn
                        floating
                        className='m-1'
                        style={{ backgroundColor: '#0082ca' }}
                        onClick={linkedinShareLink}
                        role='button'
                      >
                        <MDBIcon fab icon='linkedin-in' />
                      </MDBBtn>


                    </section>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol sm='3'>
                <MDBCard>
                  <MDBCardBody>
                    <MDBCardTitle className='fontfamily'>Delivery Info</MDBCardTitle>
                    <hr />
                    <h6><b className='fontfamily'>Free Shipping</b></h6>
                    <p className='fontfamily' > Buy 5 and more packs and get Free Shipping</p>
                    <hr />
                    <div style={{ display: "inline" }}>
                      <span className='fa-stack fa-md'>
                        <i className='fas fa-circle fa-stack-2x'></i>
                        <i className='fas fa-truck-fast fa-stack-1x fa-inverse'></i>
                      </span>
                    </div>
                    <h5><b className='fontfamily'>Door Delivery</b></h5>
                    <div className='fontfamily'  >
                      Door Delivery
                      Free shipping avaible for this product. Delivery time within 7-10 days.</div>

                    <hr />
                    {/* <div ><i style={{ paddingRight: "18px" }} className="fa-solid fa-wallet fa-lg"></i>

                      <h5 style={{ display: "inline" }}><b>Return Policy</b></h5>
                      <div style={{ fontSize: "14px" }}>Easy Return, Quick Refund. See more.</div>
                    </div> */}
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

          </>
        );
      })}
    </>
  )
}

export default ProductDetails