import React from 'react';
import "./contact.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ContactDeatails } from '../../components/JsonData/Data';


import {
  MDBContainer,
  MDBTextArea,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';

function Contact() {

  return (
    <div>
      <div>
        <div >
          <div style={{margin:"10px"}}>
            <p className='h1 hover-3 col-6 col-sm-3' style={{color:"black"}} >CONTACT</p>

          </div>
        </div>

      </div>
      <div className="col-12" style={{  marginTop: "20px",  }}>
        <div style={{display:"flex", flexDirection:"row"}}>
          {ContactDeatails.ContactDeatails.map((item) => {
            return (
              <div className='col-sm-4 col-4' style={{display:"flex",flexDirection:"column", alignItems: 'center' }}>
                <image className='col-8 col-sm-4' src={item.image} alt='image' />
                {/* <i class={item.icon}></i> */}
                <p className='contact-descript col-12' style={{ fontWeight: 'bold' }}>{item.name}</p>
                <p className='contact-descript1 col-12'>{item.decription}</p>
              </div>
            )
          })}


        </div>
      </div>


      <div expand='lg' light bgColor='light'>
        <MDBContainer fluid style={{ display: "flex", justifyContent: "space-evenly", height: "40%" }}>
          <iframe title='map' style={{ width: "100%" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30269.252448868894!2d73.66894059079415!3d18.49920964959002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc29522e4157109%3A0xec148a7d3f835363!2sSTK%20Food!5e0!3m2!1sen!2sin!4v1691495281986!5m2!1sen!2sin" allowfullscreen=""></iframe>
        </MDBContainer>
      </div>
      <div className="row col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "20px", alignItems: "center", textAlign: "center" }}>


        <form id='form' className='text-center' style={{ width: '100%', maxWidth: '100%' }}>
          <h4>Get In Touch With Us</h4>
          <div className='row' style={{ margin: "20px" }} >
            <div className=" col-sm" >
              <MDBInput label='Name' v-model='name' wrapperClass='mb-4' required />

            </div>
            <div className=" col-sm" >
              <MDBInput type='email' label='Email address' v-model='email' wrapperClass='mb-4' required />

            </div>
            <div className="col-sm" >
              <MDBInput label='Subject' v-model='subject' wrapperClass='mb-4' required />

            </div>
          </div>
          <div style={{ height: "25%" }}>
            <div className="col-12 col-sm" style={{ width: "100%", resize: "both", padding: "20px" }}>
              <MDBTextArea style={{ height: "200px" }} wrapperClass='mb-4' label='Message' required />
            </div>

          </div>
          <div className="col-12 col-sm mb-6">
            <MDBBtn style={{ backgroundColor: "rgb(49, 49, 49)", width: "50%",fontSize:"20px",fontWeight:"bold" }} block className='my-4'>
              Send
            </MDBBtn>
          </div>
        </form>
      </div>
    </div>

  )
}

export default Contact