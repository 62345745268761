import axios from "axios";



const baseURL = process.env.REACT_APP_API_URL;
const token = `Bearer ` + localStorage.getItem('token');


const configlogin = {
  headers: {    
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
  }   
};
const config = {
  headers: {'Authorization' : token,
          'Content-Type': 'application/json',        
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
  }
};


const login = async (username, password) => {
  return await axios
    .post(baseURL+ "users/authenticate/", {
      username,
      password,
    }, configlogin)
    .then((response) => {
      if(response.data.statuscode === '200'){
      if (response.data.message) {               
        localStorage.setItem("userDetails", JSON.stringify(response.data.message));  
        localStorage.setItem('token', JSON.stringify(response.data.message.token).slice(1, -1));
        localStorage.setItem('userId', JSON.stringify(response.data.message.id));
        localStorage.setItem('fullName', JSON.stringify(response.data.message.fullName));        
      return  response.data.statuscode
      }
    }
    }).catch((e) => {
     console.log(e)
    } );
}

const checkUserToken = async (userId) =>{
  const configCheck = {
    headers: {'Authorization' : `Bearer ` + localStorage.getItem('token'),
    'Content-Type': 'application/json',        
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
}
  }
  return await axios
  .get(baseURL+ "users/"+userId, configCheck)
  .then((response) => {
    // console.log("response from Url" , response)
    if(response.data.statuscode === '200'){
      return  response.data.statuscode
  }
  }).catch((e) => { 
    return e.response.data.statuscode 
  } );
}

const logout = () => {
    localStorage.setItem('userId', '');    
    localStorage.setItem('token', '');
    localStorage.setItem('fullName', '');
    localStorage.clear();

}

const registration = async (fullName,
  email,
  phoneNumber,
  username,
  password,
  referalBy) => {
  return await axios
    .post(baseURL+ "users/register/", {
        fullName,
        email,
        phoneNumber,
        username,
        password,
        referalBy }, configlogin)
    .then((response) => {
      if(response.data.statuscode === '200'){
        if (response.data.message) {               
          localStorage.setItem("userDetails", JSON.stringify(response.data.message));  
          localStorage.setItem('token', JSON.stringify(response.data.message.token).slice(1, -1));
          localStorage.setItem('userId', JSON.stringify(response.data.message.id));
          localStorage.setItem('fullName', JSON.stringify(response.data.message.fullName));    
          
console.log("registration",response.data )
window.location.href = '../cart/checkout'
        }
       }
    })
    .catch(error => console.log(error));
}

const BillingAddressDetails = async () => {
    if(token !== null){
      const userId =  localStorage.getItem('userId');
      const addressType = 1
      return await axios.post(baseURL + "users/addressdetails", {userId:userId , addressType:addressType}, config).then(
      (data) => {
        // console.log("Address Details", data.data.message)
        return  data.data.message;
      }
    )
  }
}


const ShippingAddressDetails = async () => {
  if(token !== null){
    const userId =  localStorage.getItem('userId');
    const addressType = 2
    return await axios.post(baseURL + "users/addressdetails", {userId:userId , addressType:addressType}, config).then(
    (data) => {
     return data.data.message
    })
  }
}

const TransactionDetails = async (id) => {
 return  await axios.get(baseURL + "phonepe/response/"+ id, config).then( async () => {
    return await axios.post(baseURL + "transactiondetail/merchantTransactionId",{  
      "id" : id
  } ,config).then(
    (data) => {
     return data.data.message
    })
  }
  )
 }


 const pincodedetails = async (pincode) => {
  return await axios.post(baseURL + "address/pincodedetails",{  
        "pincode" : pincode
    }).then(
      (data) => {
        if(data){
       return data.data.message
      }
      })
  }


 

const registerAddress = async (addressLine1,city,pincode,state,stateCode,emailId,phoneNumber,companyName,gstNo,role) => {
  const userId =  localStorage.getItem('userId');
  return await axios.post(baseURL + "address/register",{  
      userId: userId,
      addressType: 1,
      addressLine1: addressLine1,
      city: city,
      pincode: pincode,
      state: state,
      stateCode: stateCode,
      emailId: emailId,
      phoneNumber: phoneNumber,
      companyName: companyName,
      gstNo: gstNo,
      role: role,
  } , config).then(
    (data) => {
     return data.data.message
    })
}

const updateAdderss = async (id,addressLine1,city,pincode,state,stateCode,emailId,phoneNumber,companyName,gstNo,role) =>{
  return await axios.put(baseURL + "address/" + id,{  
    addressLine1: addressLine1,
    city: city,
    pincode: pincode,
    state: state,
    stateCode: stateCode,
    emailId: emailId,
    phoneNumber: phoneNumber,
    companyName: companyName,
    gstNo: gstNo,
    role: role
  } ,config).then(
    (data) => {
     return data.data.message
    })
}


const getUser = async (id) => {
  return await axios.get(baseURL + "users/" + id, config).then(
    (data) => {
      // console.log("data.data.message ", data.data.message )
        return data.data.message   
    }).catch(error => console.log(error))
}

const checkTokenandrefresh = async () => {

  return await axios.post(baseURL + "wallet/checkauth",config).then(
    (data) => {
      // console.log(data)
     return data.data
    })
}


const AuthService = {
    login,
    logout,
    registration,
    checkUserToken,
    BillingAddressDetails,
    ShippingAddressDetails,
    TransactionDetails,
    pincodedetails,
    registerAddress,
    updateAdderss,
    getUser,
    checkTokenandrefresh,
  }
  
  export default AuthService;