import React, { useState } from "react";
import "./Products.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBBtn, MDBRipple } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

function Products({ handleAddProduct, handleDeleteProduct, items }) {
  const [cartQuantities, setCartQuantities] = useState({});

  const handleAddToCart = (item) => {
    handleAddProduct(item);
    setCartQuantities(prevQuantities => ({
      ...prevQuantities,
      [item.slug]: (prevQuantities[item.slug] || 0) + 1
    }));
  };

  const handleIncrement = (item) => {
    setCartQuantities(prevQuantities => ({
      ...prevQuantities,
      [item.slug]: (prevQuantities[item.slug] || 0) + 1
    }));
  };

  const handleDecrement = (item) => {
    setCartQuantities(prevQuantities => {
      const updatedQuantities = {
        ...prevQuantities,
        [item.slug]: prevQuantities[item.slug] - 1
      };
      if (updatedQuantities[item.slug] === 0) {
        delete updatedQuantities[item.slug];
      }
      return updatedQuantities;
    });
  };

  const handleIncrementAndAddProduct = (item) => {
    handleIncrement(item);
    handleAddProduct(item);
  };

  const handleDecrementAndDeleteProduct = (item) => {
    handleDecrement(item);
    handleDeleteProduct(item);
  };

  return (
    <MDBContainer fluid className="mb-5 text-center">
      <MDBRow>
        {items.map(item => (
          <MDBCol sm="3" key={item.slug}>
            <MDBCard style={{ marginTop: "20px", marginBottom: "20px", alignItems: "center" }}>
              <Link to={`/details/${item.slug}`}>
                <MDBRipple rippleTag="div" rippleColor="light" className="bg-image rounded hover-zoom">
                  <MDBCardImage
                    style={{ height: "350px", width: "275px", marginTop: "20px" }}
                    src={item.image}
                    alt={item.slug}
                    fluid
                    className="w-70 imageLink"
                  />
                  <div className="hover-overlay">
                    <div className="mask"></div>
                  </div>
                </MDBRipple>
              </Link>
              <MDBCardBody>
                <h6 className="font" style={{ fontWeight: "bold" }}>{item.name}</h6>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    {[...Array(4)].map((_, i) => (
                      <i key={i} className="fa fa-star" aria-hidden="true"></i>
                    ))}
                  </div>
                  <h6 style={{ display: "inline",  fontWeight: "bold", color: "black" }}>₹ {item.price}</h6>
                </div>
                <div style={{ display: 'flex', marginTop: '15px', alignItems: 'center', marginLeft: "15px" }}>
                  <Link to={`/details/${item.slug}`}>
                    <MDBBtn className="me-1 font" style={{ backgroundColor: "#9d9d9d", fontWeight: "bold" }}>
                      Details
                    </MDBBtn>
                  </Link>
                  {cartQuantities[item.slug] ? (
                    <div className="increment" style={{ display: 'flex', alignItems: 'center' }}>
                      <MDBBtn className="font plus" onClick={() => handleDecrementAndDeleteProduct(item)}>
                        -
                      </MDBBtn>
                      <span className="number">{cartQuantities[item.slug]}</span>
                      <MDBBtn className="font minus" onClick={() => handleIncrementAndAddProduct(item)}>
                        +
                      </MDBBtn>
                    </div>
                  ) : (
                    <MDBBtn className="me-1 font " onClick={() => handleAddToCart(item)} style={{ backgroundColor: "rgb(49, 49, 49)", fontWeight: "bold" }}>
                      Add To Cart
                    </MDBBtn>
                  )}
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
}

export default Products;
