import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'
import Home from './pages/Home/Home.js';
import About from "./pages/About/About.js";
import Contact from "./pages/Contact/Contact.js";
import Header from "./components/header/Header.js";
import Navbar from "./components/Navbar/Navbar.js";
import Footer from "./components/Footer/Footer.js";
import Products from "./components/front/Products/Products.js";
import Data from "./components/Data/ProductData.js";
import Cart from "./components/front/Cart/Cart.js";
import ProductDetails from "./components/front/Products/productDetails.js";
import ScrollButton from "./components/Scrollbutton/Scroll.js";
import Vendor from "./pages/VendorEnquiry/Vendor.js";
import Franchise from "./pages/FranchiseEnquiry/Franchise.js";
import OrderSummary from "./pages/OrderSummary/OrderSummary.js";
import Logout from "./authentication/logout.js";
import Login from "./authentication/login.js";
import OrderDetails from "./pages/OrderSummary/OrderDetails.js";
import OrderAddress from "./pages/OrderSummary/Orderaddress.js";
import Checkout from "./pages/CheckOut/Checkout.js";
import Registration from "./authentication/Registration.js";
import Paymentsucess from "./components/payment/Paymentsucess.js";
import Privacypolicy from "./pages/privacypolicy/privacypolicy.js";
import Returnpolicy from "./pages/returnpolicy/returnpolicy.js";

const cartFromLocalStorage = JSON.parse(localStorage.getItem("exampleCartData") || '[]');
const freeItemFromLocalStorage = JSON.parse(localStorage.getItem("freeitem") || '[]');

function App() {
  const [cartItems, setCartItems] = useState(cartFromLocalStorage);
  const [freeItemCart, setFreeItemCart] = useState(freeItemFromLocalStorage);
  const allItems = Data;
  
  const [totalItems, setTotalItems] = useState(cartItems.reduce((qty, item) => qty + item.quantity, 0));
  const [productPrice, setProductPrice] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountpercentage, setDiscountpercentage] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [freightCharges, setFreightCharges] = useState(0);

  const getCartSummary = (TotalItem, ProductPrice, DiscountAmount, GstAmount, subtotalPrice, PromoCodes, totalPrice, discountpercentage, FreightCharges) => {
    setTotalItems(TotalItem);
    setProductPrice(ProductPrice);
    setSubtotalPrice(subtotalPrice);
    setDiscountAmount(DiscountAmount);
    let t1 = Math.round(totalPrice);
    setPayableAmount(t1);
    setPromoCode(PromoCodes);
    setDiscountpercentage(discountpercentage);
    setFreightCharges(FreightCharges);      
    setGstAmount(GstAmount);
  };

  useEffect(() => {
    localStorage.setItem("exampleCartData", JSON.stringify(cartItems));
    localStorage.setItem("freeitem", JSON.stringify(freeItemCart));
  }, [cartItems, freeItemCart]);

  const handleAddProduct = async (product) => {
    console.log("Handle Remove Product");
    const ProductExist = await cartItems.find((item) => item.id === product.id);
    if (ProductExist) {
      await setCartItems(cartItems.map((item) => item.id === product.id ? { ...ProductExist, quantity: ProductExist.quantity + 1 } : item));
    } else {
      await setCartItems([...cartItems, { ...product, quantity: 1 }]);
    }
  };

  useEffect(() => {
    const addChanaSattu = () => {
      const ProductExist = freeItemCart.find((item) => item.id === 12);   
      if (totalItems >= 4) {
        let addqty = Math.floor(totalItems / 5);      
        if (isNaN(addqty)) {
          addqty = 0;
        }
        const product = Data.find(item => item.id === 12);
        if (addqty === 0) {
          setFreeItemCart(freeItemCart.filter((item) => item.id !== 12));
        } else if (ProductExist) {
          setFreeItemCart(freeItemCart.map((item) => item.id === 12 ? { ...ProductExist, quantity: addqty } : item));
        } else {
          setFreeItemCart([...freeItemCart, { ...product, quantity: addqty }]);
        }
      }
    };
  
    addChanaSattu();
  }, [totalItems, freeItemCart]);
  
  const handleRemoveProduct = (product) => {
    const ProductExist = cartItems.find((item) => item.id === product.id);
    if (ProductExist.quantity === 1) {
      setCartItems(cartItems.filter((item) => item.id !== product.id));
    } else {
      setCartItems(cartItems.map((item) => item.id === product.id ? { ...ProductExist, quantity: ProductExist.quantity - 1 } : item));
    }
  };

  const handleDeleteProduct = async (product) => {
    await setCartItems(cartItems.filter((item) => item.id !== product.id));
  };

  return (
    <>
      <Header />
      <BrowserRouter>
        <Navbar cartItems={cartItems} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/ordersummary" element={<OrderSummary cartItems={cartItems} />} />
          <Route path="/orderdetails" element={<OrderDetails />} />
          <Route path="/address" element={<OrderAddress />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/register/:id" element={<Registration />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/returnpolicy" element={<Returnpolicy/>} />
          <Route path="/cart" element={<Cart cartItems={cartItems}
            handleAddProduct={handleAddProduct}
            handleRemoveProduct={handleRemoveProduct}
            handleDeleteProduct={handleDeleteProduct}
            items={allItems}
            sendCartSummary={getCartSummary}
            freeItemCart={freeItemCart}
          />} />
          <Route path="/shop" element={<Products handleAddProduct={handleAddProduct}
            handleDeleteProduct={handleDeleteProduct}
            items={allItems}
            cartItems={cartItems}
          />} />
          <Route path="/details/:product" element={<ProductDetails handleAddProduct={handleAddProduct} 
            handleRemoveProduct={handleRemoveProduct} cartItems={cartItems} />} />
          <Route path="/cart/checkout" element={<Checkout summary={{totalItems, productPrice, discountAmount, gstAmount, payableAmount, subtotalPrice, freightCharges, promoCode, discountpercentage}} cartItems={cartItems} freeItemCart={freeItemCart} />} />
          <Route path="/orderplaced/:id" element={<Paymentsucess />} />
        </Routes>
        <ScrollButton />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
