import React from 'react';
import { useState ,useEffect,} from 'react';
// import CloseButton from 'react-bootstrap/CloseButton';
import { ContactDeatails } from '../JsonData/Data';
import "../styles.css";
import '../header/header.css';
 


function Header() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const hasSeenPopup = sessionStorage.getItem('hasSeenPopup');

    if (!hasSeenPopup) {
      const timer = setTimeout(() => {
        setVisible(true);
        sessionStorage.setItem('hasSeenPopup', 'true');
      }, 1000); // Show popup after 1 second

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setVisible(false);
  };
  // const [show, setShow] = useState(true)

  // const ShowImage = () => {
  //   if (show === false) {
  //     setShow(true)
  //   } else {
  //     setShow(false)
  //   }
  // }
  const filtereddata = ContactDeatails.ContactDeatails.filter((data) => data.id > 1);
  return (
    <>
      <div className={`popup ${visible ? 'show' : ''}`}>
        <span className="close-btn" onClick={handleClose}>&times;</span>
        <p><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell pulse"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>Buy 5 Packs and get transportation waived off</p>
      </div>
      {/* <div className="redline">

      {
        show && (

          <div className="" >
            <div ><p className='headind-text hover'>Buy 5 Packs and get transportation waived off</p></div>
           <div className="top-right"><CloseButton onClick={ShowImage}></CloseButton></div>
          </div>

        )
      }

    </div> */}
      <div className='head'>
        <div className='col-6 header'>
          {filtereddata.map((data) => (
            <div className='headerleft' key={data.name}>
              <p className='headermenu'><i className={data.icon}></i> {data.decription}</p>

            </div>
          ))}
        </div>


        <div className='col-6  headerright'>
          <div className='links'>
            <a className='right-icons' href='/franchise'> <p className='headermenu'><i className="fa fa-shopping-bag" aria-hidden="true"></i> Become a Franchise </p></a>
          </div>
          <div className='links'>
            <a className='right-icons' href='/vendor'><p className='headermenu'><i className='fa fa-industry'></i> Vendor Enquiry</p></a>
          </div>

        </div>


      </div>
    </>
  )
}

export default Header