import '../VendorEnquiry/Vendor.css';
import {
    MDBTextArea,
    MDBInput,
    MDBBtn
  } from 'mdb-react-ui-kit';
  import vendor from '../../components/assets/vendorenq.png'
  import axios from "axios";
  import React,{useState} from 'react';

 
function Vendor() {

    const[inputs,setinputs]=useState({
        fullName: "",
        companyName: "",
        email:"",       
        phoneNumber:"",
        businessDetails:"",
        address:"",
        });

    const[msgDiv,setMsgDiv]=useState(false);
    const[msg,setMsg]=useState(false);

    const baseURL = process.env.REACT_APP_API_URL;
    const token = `Bearer ` + localStorage.getItem('token');
    const config = {
        headers: {
            'Authorization': token,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        }
    };

    const inputEvent= (event)=>{
       
        const name=event.target.name;
        const value=event.target.value;

        setinputs(prevState => ({
           ...prevState, [name]: value 
          }))
          console.log("inputs" +JSON.stringify(inputs));

}

    const savevendordata = async (e) => {
        e.preventDefault();
        
        if(inputs.phoneNumber.length < 10) {
            alert('Invalid Phonenumber')
            return
        }

        setMsgDiv(true);
        const bodyparams = {
            fullName: inputs.fullName,
            companyName: inputs.companyName,
            email:inputs.email,       
            phoneNumber:inputs.phoneNumber,
            businessDetails:inputs.businessDetails,
            address:inputs.address,
          }

        await axios
       .post(baseURL +"venderenquiry/register/",  
       bodyparams, config)
        .then(data =>{
        if(data.data.statuscode === "200"){   
            setinputs({
                    fullName: "",
                    companyName: "",
                    email:"",       
                    phoneNumber:"",
                    businessDetails:"",
                    address:"",
                })
                setMsg(true);
                   alert("Thank You for showing Interest in our company, we will contact you soon !!!")
                   window.location.href = '../'
             } 
            })
            .catch(error => console.log(error));
        }

    return (
        <div className='row vendor-main'>
            <div className='vendor-left col-6'>
                <img className='vendor-img' src={vendor} alt='' />
            </div>
            <div className='vendor-right col-12 col-sm-6'>
                <form  className='text-center container Vendor' onSubmit={(e) => savevendordata(e)} >
                    <h4 >Vendor Enquiry</h4>
                    <div className='col-12 col-sm-12' style={{ marginTop: "20px" }} >
                        <div className=" col-sm" >
                            <MDBInput label='Name' v-model='name' name="fullName" value={inputs.fullName}  onChange={inputEvent} wrapperClass='mb-4' required onKeyDown={e => {if(!(/[a-zA-Z ]/g).test(e.key)) e.preventDefault();}} />

                        </div>
                        <div className=" col-sm" >
                            <MDBInput label='Company Name' name="companyName" value={inputs.companyName}  onChange={inputEvent} v-model='Company Name'  wrapperClass='mb-4' required />

                        </div>
                        <div className=" col-sm" >
                            <MDBInput type='email' label='Email Address' name="email" value={inputs.email}  onChange={inputEvent} onKeyUp={(e) => e.target.value =  e.target.value.toLowerCase()} v-model='email' wrapperClass='mb-4' required />

                        </div>
                        <div className=" col-sm" >
                            <MDBInput  label='Phone Number' v-model='number' name="phoneNumber" value={inputs.phoneNumber}  maxLength={10} onChange={inputEvent} wrapperClass='mb-4' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} required />

                        </div>
                        <div className="col-12 col-sm" style={{ width: "100%", resize: "both", padding: "20px" }}>
                            <MDBTextArea style={{ height: "100px" }} wrapperClass='mb-4' label='Business Details' name="businessDetails" value={inputs.businessDetails}  onChange={inputEvent} required />
                        </div>
                        <div className="col-12 col-sm" style={{ width: "100%", resize: "both", padding: "20px" }}>
                            <MDBTextArea style={{ height: "100px" }} wrapperClass='mb-4' label='Address' name="address" value={inputs.address}  onChange={inputEvent} required />
                        </div>
                    </div>
                    { msgDiv ? <div className="thanks" style={{color:"red"}}>{msg ? "Thank You for showing Interest in our company, we will contact you soon !!!" : "Please wait..."}</div> : null }
                    <div className="col-12 col-sm">
                        <MDBBtn type='submit'  style={{ backgroundColor: "#f44336", width: "30vw" }} block className='my-4'>
                            Send
                        </MDBBtn>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Vendor;