const ContactDeatails = {
    ContactDeatails : [
        {
            id : 1,
            name : "Address",
            icon  : "fa fa-map-marker",
            image :"../Images/location.png",
            companyName : "STK Food Processing Pvt. Ltd.",
            decription : 'Gat No-411, Plot-87, Uravade, Pune,Maharashtra - 412115',
            
        },
        {
            id : 2,
            name : "Phone",
            icon  : "fa fa-phone",
            image :"assets/contact.png",
            companyName :"",
            decription : "+91 9953191711",
            
        },
        {
            id : 3,
            name : "Mail Id",
            icon  : "fa fa-envelope",
            image :"assets/mail.jpg",
            companyName : "",
            decription : "stkfoodprocessing@gmail.com",
            
        },
    ]
}

const Aboutimage = { 
    Aboutimage : [
    { id: 1,
        name: "Big image",
        image:"/assets/product3.jpg",
        description:"we believe the best way deliver a great user experience is by deeply understanding what people want and love. then deliver the features, messages, and content that are most helpful, relevant and timely. that's what makes users happy and loyal, kissmetrics strives to deliver the tools and support that helps companies deliver that great experience. we want our customers customers to be happy, so then our customers are happy and that makes us happy.",
        
    }
]
}
const aboutData = { 
    aboutData : [
    {
        id: 1,
        name: "Massive Range Of Products",
        image:"/assets/about-massive.png"
    },
    {
        id: 2,
        name: "Effective Order Management",
        image:"/assets/about-effectice.png"
    }, 
    {
        id: 3,
        name: "Multiple Payment Option",
        image:"/assets/about-multiple.png"
    }, 
    {
        id: 4,
        name: "Multiple Shipping Option",
        image:"/assets/about-shipping.png"
    }, 
    {
        id: 5,
        name: "Special Deals And Offers",
        image:"/assets/about-deals.png"
    },     
]
}
const CircleImages = {
    CircleImages : [
    {
        id : 1,
        name : "FIBRE RICH",
        image: "/assets/fibrerich.jpg"
    },
    {
        id : 2,
        name : "CHOLESTEROL FREE",
        image: "/assets/cholesterolfree.jpg"
    },
    {
        id : 3,
        name : "GLUTEN FREE",
        image: "/assets/glutenfree.jpg"
    },
    {
        id : 4,
        name : "IRON RICH",
        image: "/assets/ironrich.jpg"
    },
    {
        id : 5,
        name : "DAIRY FREE",
        image: "/assets/dairyfree.jpg"
    },
    {
        id : 6,
        name : "MALTODEXTRIN FREE",
        image: "/assets/nomaltodextrin.jpg"
    },{
        id : 7,
        name : "LOW SALT",
        image: "/assets/lowsalt.jpg"
    },{
        id : 8,
        name : "PROTEIN FREE",
        image: "/assets/protinfree.jpg"
    },{
        id : 8,
        name : "VEGAN",
        image: "/assets/vegan.jpg"
    }
]
}


const AddressData = {
    AddressData : [
    {
        id : 1,
        firstName : "Mrs. Sangeetha",
        lastName : "Thankur",
        companyName : "STK Food Processing Pvt. Ltd.",
        Address :"Gat No-411, Plot-87, Uravade, Pune, Maharashtra - 412115",
        Email :"stkfoodprocessing@gmail.com",
        Phone :"+91 9953191711",
        GstNo :"AAAAA0000A",
        billingInstruction: "billling instruction",

    }
]
}
 

const Slider =
{
    Slider :[
    {
        id : 1,
        image: "../Images/Black_Paper_Bowl-removebg-preview.png" ,
        
        href : "https://popmak.in",
        title : "title 1",
        description1 :"Black Pepper & Himalayan Salt",
        description2 :"Made with good quality ground pepper and rock salt. Great fasting snack. Sugar Free...!"
        
    },
    {
        id : 2,
        image: "../Images/Coriander_Delight_Bowl-removebg-preview (2).png" ,
        title : "title 2",
        description1 :"Coriander Delight",
        description2 :"Nature’s Flavor: Coriander Delight Makhana Magic"
        

    },
    {
        id : 3,
        image: "../Images/Cream_And_Onion_Bowl-removebg-preview.png", 
        image1: "/assets/slide3.jpeg" ,
        title : "title 2",
        description1 :"Sour Cream n Onion",
        description2 :"Kids Favorite!!! sweet ans salty flavor is widely loved by the lil champs!!!"
        

    },
    {
        id : 4,
        image: "../Images/Indian_Spices_Bowl-removebg-preview.png", 
        title : "title 2",
        description1 :"Indian Spices",
        description2 :"Makhana roasted and tossed in blend of Indian spices. Medium spicy and is loved by all age groups. No unnessary additives like maltodextrin, HVP, E631, E627, etc."
        

    },
    {
        id : 5,
        image: "../Images/Spanish_Tomato_Bowl-removebg-preview.png", 
        title : "title 2",
        description1 :"Spanish Tomato",
        description2 :"A Taste of Spain: Savor the Flavor of Spanish Tomato Makhana!"
        

    },
    {
        id : 6,
        image: "../Images/Mumbai_chat_Bowl-removebg-preview.png", 
        title : "title 2",
        description1 :"Mumbai Chat",
        description2 :"So natural and sugar free. Made in natural rock salt. No table salt. No asafoetida. Gluten Free."
        

    },
    {
        id : 7,
        image: "../Images/Peri_Peri_Bowl-removebg-preview.png", 
        title : "title 2",
        description1 :"Peri Peri",
        description2 :"Spice Up Your Snack Time with Peri Peri Makhana: A Fiery, Flavorful Crunch That Will Leave You Wanting More."
        

    },
    {
        id : 8,
        image: "../Images/Hevenly_cheez_Bowl-removebg-preview.png", 
        title : "title 2",
        description1 :"Heavenly Cheese",
        description2 :"Indulge in the Divine Fusion of Creamy Cheese and Crunchy Delight"
        

    },
    {
        id : 9,
        image: "../Images/Pink_Salt_Bowl-removebg-preview.png", 
        title : "title 2",
        description1 :"Pink Salt with Desi Ghee",
        description2 :"Experience the Perfect Harmony of Pink Salt and Desi Ghee: A Deliciously Balanced Makhana Delight"
        

    },
    {
        id : 10,
        image: "../Images/Jowar_Puff_Front_Updated-removebg-preview.png", 
        title : "title 2",
        description1 :"Popmak Jowar Puff Peri Peri",
        description2 :"Spice up your snacking with POPMAK Jowar Puff Peri Peri: A fiery burst of flavor in every crunchy bite !"
        

    },
    {
        id : 11,
        image: "../Images/Raw_Makhana_100_g_front_new-removebg-preview.png", 
        title : "title 2",
        description1 :"Popmak Taal Makhana",
        description2 :"Discover Exotic Crunch with Popmak Taal Makhana: A Heavenly Fusion of Flavor and Crunchiness!"
        

    },
    {
        id : 12,
        image: "../Images/DSC_6457-removebg-preview.png", 
        title : "title 2",
        description1 :"Popmak Ragi Millet Dosa Mix",
        description2 :"Craft Authentic Delights with Popmak Ragi Millet Dosa Mix: Elevating Tradition with Wholesome Goodness!"
        

    },
  
    {
        id : 14,
        image: "../Images/DSC_6458-removebg-preview.png", 
        title : "title 2",
        description1 :"Popmak Roasted Chana Peri Peri",
        description2 :"Turn up the heat with Popmak Roasted Chana Peri Peri: Bold flavor, crunchy goodness, and a spicy kick in every bite!"
        

    },
    {
        id : 15,
        image: "../Images/Chana_Sattu-removebg-preview.png", 
        title : "title 2",
        description1 :"Chana Sattu",
        description2 :"Energize Your Snack Time with Chana Sattu Makhana: Nutrient-packed Crunchiness for a Healthy Bite!"
        

    },
]
}
const Sliderlow =  {
    Sliderlow : [
        {
            id: 1,
            name : "POPMAK",
            image : "/assets/aboutsection.jpeg",
            description : "we aim to bridge the gap between nature and consumers. Our team is committed to delivering the wonderful gifts that nature has in store, in form of healthy, nutritious and organic foods.The aim is to create an offering by combining the natural foods with modern techniques so as to enable the human kind to rejoice these gifts.",
        },
        {
            id: 2,
            name : "New Arrival",
            image : "/assets/customized.jpeg",
            description : "It is a healthy munching solution for all ages. In fact mothers have started giving it space in their kids lunch boxes. We are happy & sure to bring in such product line up which can add values in your life. We are just four years old company, aiming big to deliver and in this endeavor we will remain child like enthusiast for ever “Expect more surprises from us soon!!!!",
        },
        {
            id: 3,
            name : "POPMAK",
            image : "/assets/product3.jpg",
            description1 : "Goal : Epicenter of our existence defines our goal i.e. to be the most revered organization in the healthy snacks and organic food market over the period of 10 years.",
            Objective : "Objective : Foremost objective is to provide the nutritional healthy packaged food to Consumer. We aim to create values for all the associated stake holders.",
            objective2: "",
        },
    ]
}

const CompanyData = {
   CompanyData:[    {
        id : 1,
        firstName : "STK Foods",
        lastName : "POPMAK",
        companyName : "STK Food Processing Pvt. Ltd.",
        Address :"pune 412207",
        Email :"stkfoodprocessing@gmail.com",
        Phone :"+91 9953191711",
        GstNo :"AAAAA0000A",
        billingInstruction: "here are some billling instruction",

    }
]
}




const orderCountCart = {
   orderCountCart : [
        {
            id : 1,
            name: " Product in Cart",
            Count :"",
            icon: "fa fa-shopping-cart",
            href: "/cart"
        },
     
    ]

}


const billingData = {
    billingData : [
        {
            id: 1,
            firstName: "gd",
            lastName:"qbc",
            companyName:"hjfhhfhhfh",
            address:"sjjs",
            emailAddress:"sdasdas@gsgs.com",
            phoneNumber:7474747,
            gstNo:"3123d1234eds",
            billingInstruction:"dgdgdgdfcsgfdgdf"
        },
     
    ]

}
const sloganData = {
    sloganData :[
        {
            id:1,
            name:"Sanyog",
            slogan:"Delicious Product and Very Good Quality. Loved the flavour. Great value for money while keeping a check on your health!",
        },
        {
            id:2,
            name:"Sanyog1",
            slogan:"Delicious Product and Very Good Quality. Loved the flavour. Great value for money while keeping a check on your health!",
        },
        {
            id:3,
            name:"Sanyog3",
            slogan:"Delicious Product and Very Good Quality. Loved the flavour. Great value for money while keeping a check on your health!",
        },
    ]
}

export {
    Aboutimage,sloganData, aboutData, AddressData, CircleImages, ContactDeatails, Slider,  Sliderlow, CompanyData,billingData,orderCountCart 
};
