import React, {useState} from 'react'
import { MDBContainer, MDBRow, MDBCol,MDBCard,MDBCardBody,MDBInput,MDBCheckbox, MDBBtn } from 'mdb-react-ui-kit'
import {Form} from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import AuthService from '../servicecall/auth.service';
import { Link } from 'react-router-dom/dist';
import "./signup.css"


const Login = (props) => {
const [username, setUsername] = useState("");
const [password, setPassword] = useState("");
// const[Message,setMessage]=useState();

const [isInvalid, setIsInvalid] = useState(false);
// const [isLoggin, setisLoggin] = useState(false);

// const checkLogin = async () => {
//   if (localStorage.getItem('token')) {
//     await setisLoggin(true);
//   } else {
//     await setisLoggin(false);
//   }

// }

// const navigate = useNavigate();

const handleSubmit = async(e) => {
      e.preventDefault();
      let login = await AuthService.login( username, password);
      if(login === 400){
        await setIsInvalid(true);
      }else{
        // checkLogin();
        if(localStorage.getItem('token')){
          window.location.href = '../ordersummary'
        }
      }
}
  return (
   <>
   <div className={(isInvalid) ? 'invalid-msg show' : 'invalid-msg'}>Invalid Username or Password</div>
   <Form onSubmit={handleSubmit}>
     <MDBContainer fluid>
              <MDBRow className='d-flex justify-content-center align-items-center h-100 font1'>
                <MDBCol col='12'>
                  <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                    <MDBCardBody className='p-5 w-100 d-flex flex-column'>
                      <h2 className="fw-bold mb-2 text-center" style={{ color: "red" }}>Login</h2>
                      <p className="text-white-50 mb-3">Please enter your login and password!</p>
                      <MDBInput wrapperClass='mb-4 w-100' label='UserName' id='formControlLg' type='text' size="lg" onChange={(e) => setUsername(e.target.value)}/>
                      <MDBInput wrapperClass='mb-4 w-100' label='Password' id='formControlLg' type='password' size="lg"   onChange={(e) => setPassword(e.target.value)} />
                      <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4' label='Remember password' />
                      
                      {/* <p>{Message}</p> */}
                      <MDBBtn size='lg'  className="hideLogin" style={{ background: "red", fontWeight: "bold" ,}} type="submit" onClick={(e)=> {handleSubmit(e)}}  >
                        Login
                      </MDBBtn>
                      <p className="mb-5 pb-lg-2" style={{ color: '#393f81', margin: "15px" }}><Link to={'/register'}>Register</Link></p>
                      <hr className="my-2" />
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>

  </Form>
   </>
  )
}

export default Login