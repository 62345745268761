import React  from 'react';
import './sliderlow.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../sliderlow/sliderlow.css';
import { CircleImages } from '../JsonData/Data';

function Silederlow() { 

  return (
    <div className="container" >

      <div className="row col-12" style={{ marginTop: "10px", marginBottom: "10px",   }}>
        <div className=" col-sm circle-icons" >
          {CircleImages.CircleImages.map((item, index) => {
            return (
              <div key={"SliderLow"+index} style={{marginLeft: '8px'}} >
                <div className="frame ">
                  <image src={item.image} alt='image' className="circle-img" />
                  <div className="details1">
                    <p className='circle-img-name' >{item.name}</p>
                  </div>
                </div>
                {/* <p className='font1em' >{item.name}</p> */}
              </div>
            )
          })}
        </div>
      </div>
    </div>


  )
}

export default Silederlow