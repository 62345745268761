import React  from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './carsoul.css'

 
export default function Carsoul( {images, sendImage} ) {

    var settings = {
   
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              arrows: true
            }
          }
        ]
      };
    
      return (
       <>
         <Slider {...settings}>
         {images.map((obj) => { 
      return (
       <>
          <div className="image1" onClick={() => sendImage(obj.imagepath)} >
            <img src={obj.imagepath} style={{width:"100%"}}  className="img" alt={obj.alttag} />
          </div>
        </>)
        })}
        </Slider>
        </>

  );
}





