import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Slider } from '../JsonData/Data';
import '../styles.css';
import './slider.css'; // assuming you have a separate CSS file for slider styles
import AuthService from '../../servicecall/auth.service';

// Define the getWhatsAppUrl function
const getWhatsAppUrl = (referralCode) => {
    return `https://api.whatsapp.com/send?text=
        I'm inviting you to check out POPMAK,
        a healthy snack store.
        Use my referral code to get ₹50 
        in your POPMAK wallet.
        refrealCode : ${referralCode}
        https://popmak.in/register/${referralCode}`;
};

function SliderImages() {
    const userIdData = localStorage.getItem('userId');
    const userId1 = userIdData ? JSON.parse(userIdData) : null;
    const [referalButton, setReferalButton] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const descriptionRefs = useRef([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userId1) {
                    const userToken = await AuthService.getUser(userId1);
                    if (userToken) {
                        setReferalButton(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, [userId1]);

    // Function to handle slide change
    const handleSlideChange = (slideIndex) => {
        setCurrentSlide(slideIndex);
    };

    // Function to animate descriptions
    const animateDescriptions = () => {
        descriptionRefs.current.forEach((ref, index) => {
            if (ref) {
                if (index === currentSlide) {
                    ref.classList.add('fadeInLeft'); // Apply fade-in animation class
                } else {
                    ref.classList.remove('fadeInLeft'); // Remove animation class from other descriptions
                }
            }
        });
    };

    // Update descriptionRefs array when the number of slides changes
    useEffect(() => {
        descriptionRefs.current = descriptionRefs.current.slice(0, Slider.Slider.length);
        animateDescriptions(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlide]);
    

    // useEffect to remove animation class on component unmount
    useEffect(() => {
        return () => {
            descriptionRefs.current.forEach((ref) => {
                if (ref) {
                    ref.classList.remove('fadeInLeft');
                }
            });
        };
    }, []);

    return (
       
            <Carousel fade interval={3000} onSlide={handleSlideChange}   >

                {Slider.Slider.map((slide, index) => {
                    let content;
                    if (slide.image === '/assets/slide1.jpg' && referalButton) {
                        const referralCode = ''; // Get referral code from userToken
                        const whatsappUrl = getWhatsAppUrl(referralCode);
                        content = (
                            <button className="overlay-button" onClick={() => window.open(whatsappUrl)}>
                                Refer Now
                            </button>
                        );
                    } else {
                        content = null;
                    }

                    return (

                        <Carousel.Item key={`slider-${index}`} style={{ zIndex: "0" }} >

                            <div className="slider-content"   >

                                <div className="text-content">
                                    <div
                                        ref={(ref) => (descriptionRefs.current[index] = ref)}
                                        className="text-content1"
                                    >
                                        <p className="description1">{slide.description1}</p>
                                    </div>
                                    <div
                                        ref={(ref) => (descriptionRefs.current[index] = ref)}
                                        className="text-content2"
                                    >
                                        <p className="description2">{slide.description2}</p>
                                    </div>
                                </div>
                                <div className="image-content">
                                    <img className="d-block w-100" src={slide.image} alt={`Slide ${index}`} />
                                </div>
                                {content}
                            </div>

                        </Carousel.Item>
                    );
                })}

            </Carousel>
      
    );
}

export default SliderImages;
