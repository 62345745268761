import React, { useState } from 'react'
import { MDBInputGroup, MDBBtn } from 'mdb-react-ui-kit'
const Promocode = ({ applyPromoCode, setPromoCodefn, promoAppliedMessage, discountAmount, promoApplied }) => {

  const [isPromocodeDisable, setIsPromocodeDisable] = useState("block")
  const [hideAncher, sethideAncher] = useState("hidden")
  const [hideApplyButton, sethideApplyButton] = useState("block")
  // const [show, setShow] = useState(true);

  const handlePromoCode = () => {
    applyPromoCode()
    sethideApplyButton("block")
    setIsPromocodeDisable('text')
    sethideAncher("none");
  }

  return (
    <>
      <div>
        <button
          style={{ display: hideAncher }}
          onClick={() => {
            setIsPromocodeDisable("text");
            sethideAncher("none");
            sethideApplyButton("block");
          }}
        >
          Have a promocode?
        </button>

        {/* {show ?  */}
        <div>
          <MDBInputGroup >
            <input className='form-control' placeholder="Promo Code" type={isPromocodeDisable} name="couponCode" onChange={(e) => (setPromoCodefn(e.target.value))} />
            <MDBBtn outline style={{ display: hideApplyButton }}
              onClick={handlePromoCode}
            >Apply</MDBBtn>     <br />
            <span style={{ fontSize: '12px', color: "blue" }}> {promoAppliedMessage}</span>
            {/* {(promoApplied) ?<span> {promoAppliedMessage}</span> : "" } */}


          </MDBInputGroup>

        </div>
        {/* : null} */}

      </div>
    </>

  )
}

export default Promocode