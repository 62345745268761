import React from "react";
import { useEffect, useState } from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader,  MDBCol,  MDBIcon, MDBInput, MDBListGroup, MDBListGroupItem, MDBRipple, MDBTypography, MDBRow} from "mdb-react-ui-kit";
import './Cart.css'
import Promocode from "./promocode";
import axios from "axios";
// import Link from "cdbreact/dist/components/Link";
import { Link } from "react-router-dom";
import { right } from "@popperjs/core";

// import CartRegistration from "../../../authentication/cartRegistration";

const Cart = ({ cartItems, handleRemoveProduct, handleAddProduct, handleDeleteProduct, sendCartSummary, freeItemCart }) => {

  // console.log("sendCartSummary in Cart" ,sendCartSummary)
  // const [] = useState({
  //   couponCode: "",
  // });
  const [ setPromoCodeDetails] = useState() 
  const [promoApplied, setpromoApplied] = useState(false);
  const [promoAppliedMessage, setpromoAppliedMessage] = useState(""); 
   
  const [totalPrice, settotalPrice] = useState(0);
  const [subtotalPrice, setsubtotalPrice] = useState(0.00);
  const [freightCharges, setfreightCharges] = useState(0.00);
  const [discountpercentage, setdiscountpercentage] = useState(0)
  const [discountAmount, setDiscountAmount] = useState(0)  
  const [calculationData, setCalculationData] = useState([])
  const [totalQuantity, setTotalQuantity] = useState();
  const [promoCode, setPromoCode] = useState('')
  const [gstAmount, setgstAmount] = useState(0)





  const baseURL = process.env.REACT_APP_API_URL;
  const token = `Bearer ` + localStorage.getItem('token');
  const config = {
    headers: {
      'Authorization': token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
  };
  
  const calculateAll = () => {
    console.log("In Calculate All")
  const getCalculationData = cartItems?.map((items) => {
// console.log("discountpercentage" , discountpercentage, "items.price :" , items.price , "items.quantity : ", items.quantity , "items.taxpercent", items.taxpercent );
let itmPrice = items.basePrice -  ( items.basePrice * discountpercentage / 100);
let discountPrice = items.basePrice - itmPrice;
// console.log(" -------------------------- itmPrice --------------------------- ", items)
  const prSubtotal = (parseFloat(itmPrice) * parseFloat(items.quantity) ) 
  const prDiscount = (parseFloat(discountPrice) * parseFloat(items.quantity))
const prGST = (parseFloat(prSubtotal) * (parseFloat(items.taxpercent) /100)) 
const totalAmount = (parseFloat(prSubtotal) + parseFloat(prGST))  ;
  return {
  "calId": items.id,
  "calPrice": items.basePrice,
  "calDicountedPrice" :discountPrice, 
  "calDiscountAmount" : prDiscount,
  "calQuantity": items.quantity,
  "calSubtotal": prSubtotal,
  "calGstAmount" : prGST,
  "caltotalAmount" : totalAmount
 }
 
})

setCalculationData(getCalculationData)
let prodprice = getCalculationData.reduce((price, item) => parseFloat(price) + parseFloat(item.calPrice), 0)
let disAmnt = getCalculationData.reduce((disc , item ) => parseFloat(disc) +  parseFloat(item.calDiscountAmount), 0)
let subTotPrice = getCalculationData.reduce((subtot , item ) => parseFloat(subtot) +  parseFloat(item.calSubtotal), 0)
// setproductPrice(prodprice)
setTotalQuantity(getCalculationData.reduce((qty , item ) => parseInt(qty) +  parseInt(item.calQuantity), 0))
setsubtotalPrice(subTotPrice)
setDiscountAmount(disAmnt)
// setPrice(getCalculationData.reduce((price, item) => parseFloat(item.calpriceAfterDiscount) * parseFloat(item.calQuantity), 0))
let freightAmount = 0;
let gstPreamount = getCalculationData.reduce((pGst , item ) => parseFloat(pGst) +  parseFloat(item.calGstAmount), 0) ;
      let gstAmount1 = getCalculationData.reduce((pGst , item ) => parseFloat(pGst) +  parseFloat(item.calGstAmount), 0) ;
     let totalQuantity1 =  getCalculationData.reduce((qty , item ) => parseInt(qty) +  parseInt(item.calQuantity), 0)
     let perpeaceGstCharges = 0;
     if(totalQuantity1 === 1){
        freightAmount = 50;
        perpeaceGstCharges = 9;
      } else if(totalQuantity1 === 2){
        freightAmount = 80;
        perpeaceGstCharges = 14.40;
      } else if(totalQuantity1 === 3){
        freightAmount = 105;
        perpeaceGstCharges = 18.9;
      } else if(totalQuantity1 === 4){
        freightAmount = 120;
        perpeaceGstCharges =21.60;
      } else {
        freightAmount = 0; 
      }      
      gstAmount1 = parseFloat(gstPreamount) + parseFloat(perpeaceGstCharges)      
setgstAmount(gstAmount1)
      setfreightCharges(freightAmount) 
      // console.log("getCalculationData    ", getCalculationData)  
      const gtotalAmt =  getCalculationData.reduce((subtot , item ) => parseFloat(subtot) +  parseFloat(item.calSubtotal), 0)  + parseFloat(gstAmount1) + parseFloat(freightAmount)   
// settotalPrice(getCalculationData.reduce((gTot , item ) => parseFloat(gTot) +  parseFloat(item.caltotalAmount) +  parseFloat(freightAmount) * 1.18 , 0))
 settotalPrice(gtotalAmt)
sendCartSummary(totalQuantity1, prodprice, disAmnt, gstAmount1, subTotPrice, promoCode, gtotalAmt, discountpercentage, freightAmount)
}
useEffect(()=> {
  calculateAll();
  // sendCartSummary(totalQuantity, productPrice, discountAmount, gstAmount, subtotalPrice, promoCode, totalPrice, discountpercentage, freightCharges)
},[cartItems, discountpercentage])

    const setPromoCodefn = (value) => {
    setPromoCode(value)
  }

  const applyPromoCode = async () => {
    const bodyparams = {
      couponCode: promoCode,
    };
  
    await axios
      .post(baseURL + "coupons/promodetails", bodyparams, config)
      .then(data => {
        if (data.data.statuscode === "200") {
          setPromoCodeDetails(data.data.message);
          setdiscountpercentage(data.data.message.discoutDetails);
          calculateAll();
          setpromoApplied(true);
          setpromoAppliedMessage("Promocode " + promoCode + " is Applied Successfully with " + Number(data.data.message.discoutDetails) + "% Discount");
        }
      })
      .catch(error => { // Wrap the statements inside a function
        setPromoCodeDetails('');
        setdiscountpercentage(0);
        calculateAll();
        setpromoApplied(false);
        setpromoAppliedMessage("Promocode " + promoCode + " is not Valid. Please enter a valid PromoCode");
        // console.log(error);
      });
  };
  


  return (
      <section className="gradient-custom" >
        <div className=" h-80">
          <MDBRow className="justify-content-center my-4 ">
            <MDBCol md="8">
              <MDBCard className="mb-4">
                <MDBCardHeader className="py-3">
                  <MDBTypography tag="h5" className="mb-0 name1" style={{ fontWeight: "bold" ,color:"rgb(49, 49, 49)"}}>Cart    <span style={{textAlign : right, marginLeft: '50%'}}> Buy 5 units and get Chana Sattu free</span>
                  </MDBTypography>
                  {cartItems.length === 0 && (
                    <div className="cart-items-empty"><h6 style={{ fontWeight: "bold" }}>Cart Is Empty</h6></div>
                  )}
                </MDBCardHeader>
                <MDBCardBody >
                  {/* <MDBAccordion initialActive={1}> */}
                  {cartItems.map((item) => (
                    // <MDBAccordionItem collapseId={item.id} headerTitle={item.name}>
                    <MDBRow className="cart-info">
                      <MDBCol lg={4} md={4} sm={3} xs={3} className="bottom">
                        <MDBRipple rippleTag="div" rippleColor="light"
                          className="bg-image rounded hover-zoom hover-overlay">
                          <img src={item.image} alt={item.name} style={{ maxHeight: '150px', aspectRatio: 'auto' }} />
                        </MDBRipple>
                      </MDBCol>
                      <MDBCol lg={4} md={4} sm={3} xs={3} className="mb-lg-0 product-info" >
                        <div >
                          <strong className="name">{item.name}</strong>
                          {
                            item.customizedPacks ?
                              item.customizedPacks.slice(0, 1).map(objval => {
                                return (
                                  <div>
                                    <p style={{ textAlign: "left" }}>Made In Filtered : {objval.selectedfilter} </p>
                                    <p style={{ textAlign: "left" }}>Type of Salt :{objval.selectedsalt} </p>
                                  </div>)
                              }

                              )
                              : ""
                          }

                        </div>

                        <div className='d-flex justify-content-between align-items-center px-0 pb-0'>
                          <p>₹ {item.price} <em>(inclusive of {item.taxpercent}% GST)</em></p>
                        </div>
                        <div>
                          <strong className="name"> Total Amount: ₹ {(item.price * item.quantity).toFixed(2)}</strong>
                        </div>
                      </MDBCol>
                      <MDBCol lg={4} md={4} sm={3} xs={3} className="bottom" style={{ display: "flex", flexDirection: "column", gap: "10%", alignItems: 'center' }} >
                        <div className="d-flex  plus-minus-btn product-count" >
                          <MDBBtn className="px-4 me-1 btn-color" onClick={() => handleRemoveProduct(item)} >
                            <MDBIcon fas icon="minus" />
                          </MDBBtn>
                          <MDBInput defaultValue={1} min={0} type="text" className="cart-total name" id="itemQuantity" value={item.quantity} />
                          <MDBBtn className="px-4 ms-1 btn-color" onClick={() => handleAddProduct(item)}>
                            <MDBIcon fas icon="plus" />
                          </MDBBtn>
                        </div>
                        <div className='inline d-flex justify-content-between align-items-center px-0 pb-0'>
                          <MDBBtn onClick={() => {
                            handleDeleteProduct(item);
                          }} className="delete-btn name">Delete</MDBBtn>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    // </MDBAccordionItem>
                  ))}
                  {/* </MDBAccordion> */}
                </MDBCardBody>


                {/* Free Items In cart */}

                <MDBCardBody >
                  {/* <MDBAccordion initialActive={1}> */}
                  {freeItemCart.map((item) => (
                    // <MDBAccordionItem collapseId={item.id} headerTitle={item.name}>
                    <MDBRow className="cart-info">
                      <MDBCol lg={4} md={4} sm={3} xs={3} className="bottom">
                        <MDBRipple rippleTag="div" rippleColor="light"
                          className="bg-image rounded hover-zoom hover-overlay">
                          <img src={item.image} alt={item.name} style={{ maxHeight: '150px', aspectRatio: 'auto' }} />
                        </MDBRipple>
                      </MDBCol>
                      <MDBCol lg={4} md={4} sm={3} xs={3} className="mb-lg-0 product-info" >
                        <div >
                          <strong className="name">{item.name}</strong>

                          {
                            item.customizedPacks ?
                              item.customizedPacks.slice(0, 1).map(objval => {
                                return (
                                  <div>
                                    <p style={{ textAlign: "left" }}>Made In Filtered : {objval.selectedfilter} </p>
                                    <p style={{ textAlign: "left" }}>Type of Salt :{objval.selectedsalt} </p>
                                  </div>)

                              }

                              )
                              : ""
                          }

                        </div>

                        <div className='d-flex justify-content-between align-items-center px-0 pb-0'>
                          <p><span style={{ textDecoration: "line-through" }}>₹ {item.price}</span> ₹ 0.00</p>
                          <p>Freebie</p>
                        </div>
                      </MDBCol>
                      <MDBCol lg={4} md={4} sm={3} xs={3} className="bottom" style={{ display: "flex", flexDirection: "column", gap: "10%", alignItems: 'center' }} >
                        <div className="d-flex  plus-minus-btn product-count" >
                          <MDBInput defaultValue={1} min={0} type="text" className="cart-total" id="itemQuantity" value={item.quantity} />
                         </div>
                        {/* <div className='inline d-flex justify-content-between align-items-center px-0 pb-0'>
                         
                        </div> */}
                      </MDBCol>
                    </MDBRow>
                  ))}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="4">
              <MDBCard className="mb-4" style={{ height: "500px" }}>

                <MDBCardHeader >
                  <hr />
                  <MDBTypography tag="h5" className="mb-0 name summary">
                    Summary
                  </MDBTypography>
                </MDBCardHeader>
                <MDBCardBody>
                  <MDBListGroup flush>
                    <MDBListGroupItem
                      className="d-flex justify-content-between align-items-center border-0 px-0 pb-0 name" >
                      Total Quantity
                      <span>{totalQuantity}</span>
                    </MDBListGroupItem>

                  <MDBListGroupItem
                      className="d-flex justify-content-between align-items-center border-0 px-0 name">
                      <div>
                        <h6> Sub Total amount</h6>
                      </div>
                      <span>
                        <h6 >₹ {(subtotalPrice).toFixed(2)}</h6>
                      </span>
                    </MDBListGroupItem>
                    <MDBListGroupItem
                      className="d-flex justify-content-between align-items-center border-0 px-0 name">
                      <div>
                        <h6> Shipping Charges </h6>
                      </div>
                      <span>
                        <h6 >₹ {(freightCharges).toFixed(2)}</h6>
                      </span>
                    </MDBListGroupItem>

                    <Promocode className="name" applyPromoCode={applyPromoCode} setPromoCodefn={setPromoCodefn} promoAppliedMessage={promoAppliedMessage} discountAmount ={discountAmount} promoApplied={promoApplied} />
                    
                    <MDBListGroupItem
                      className="d-flex justify-content-between align-items-center border-0 px-0">
                      <div>
                        <h6>Discount Amount </h6>
                      </div>
                      <span>
                        <h6 >₹ {(discountAmount).toFixed(2)}</h6>
                      </span>
                    </MDBListGroupItem>

                    <MDBListGroupItem
                      className="d-flex justify-content-between align-items-center border-0 px-0">
                      <div>
                        <h6>GST Amount</h6>
                      </div>
                      <span>
                        <h6 >₹ {(gstAmount).toFixed(2)}</h6>
                      </span>
                    </MDBListGroupItem>
                    <MDBListGroupItem
                      className="d-flex justify-content-between align-items-center border-0 px-0">

                      <div>
                        <strong className="name">Total amount</strong>
                        <strong>
                          <p className="mb-0 name">(including Gst)</p>
                        </strong>
                      </div>

                      <span>

                        <strong className="name">₹ {(totalPrice).toFixed(2)}</strong>

                      </span>
                    </MDBListGroupItem>
                  </MDBListGroup>
                  <Link to="/cart/checkout">
                    <MDBBtn block size="lg" className="btn-color name" >
                      Go to checkout
                    </MDBBtn>
                  </Link>
                  {/* } */}
                </MDBCardBody>
              </MDBCard>
              <MDBCard className="mb-4">
                <MDBCardBody>
                  <p>
                    <strong >Expected shipping delivery</strong>
                  </p>
                  <p className="mb-0 name" >Within 4 to 6 Days</p>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
      </section>
  );
};
export default Cart;