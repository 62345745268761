import React from "react";

import ReactImageMagnify from "react-image-magnify";

const MagnifyImage = (image) => {
const imagepath = JSON.stringify(image.image).replace(/['"]+/g, '');

  return (
    <div> 
      <ReactImageMagnify
        {...{
          smallImage: {
            alt: "image" + imagepath.replace(/['"]+/g, ''),
            isFluidWidth: true,
            src:imagepath.replace(/['"]+/g, ''),
            
          },
          largeImage: {
            src:JSON.stringify(image.image).replace(/['"]+/g, '') ,
         
            width: 1200,
            height: 1800,
          
          },
          enlargedImageContainerStyle: {
            zIndex: 9
          },
          enlargedImageContainerDimensions: {
            width: "100%",
            height: "100%",
          },
        }}
      />
    </div>
  );
};

export default MagnifyImage;


